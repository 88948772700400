import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'shared-lib';

export class ResidentsService {
  private static instance: ResidentsService;
  private functions;

  private constructor() {
    this.functions = getFunctions(undefined, 'europe-west3');
  }

  public static getInstance(): ResidentsService {
    if (!ResidentsService.instance) {
      ResidentsService.instance = new ResidentsService();
    }
    return ResidentsService.instance;
  }

  public async approveResidencyRequest(locationId: string, requestId: string, role: 'resident' | 'admin' | 'privileged' | 'blocked'): Promise<{ success: boolean; message: string }> {
    const manageResidencyRequest = httpsCallable(this.functions, 'manageResidencyRequest');
    const result = await manageResidencyRequest({
      action: 'approve',
      params: {
        locationId,
        requestId,
        role
      }
    });
    return result.data as { success: boolean; message: string };
  }

  public async rejectResidencyRequest(locationId: string, requestId: string, rejectionReason: string): Promise<{ success: boolean; message: string }> {
    const manageResidencyRequest = httpsCallable(this.functions, 'manageResidencyRequest');
    const result = await manageResidencyRequest({
      action: 'reject',
      params: {
        locationId,
        requestId,
        rejectionReason
      }
    });
    return result.data as { success: boolean; message: string };
  }

  public async removeUser(locationId: string, userId: string): Promise<{ success: boolean; message: string }> {
    const manageResidencyRequest = httpsCallable(this.functions, 'manageResidencyRequest');
    const result = await manageResidencyRequest({
      action: 'remove',
      params: {
        locationId,
        userId
      }
    });
    return result.data as { success: boolean; message: string };
  }

  public async updateUserRole(locationId: string, userId: string, role: 'resident' | 'admin' | 'privileged' | 'blocked'): Promise<void> {
    const userDocId = `${userId}_${locationId}`;
    const userRef = doc(db, 'locationUsers', userDocId);
    await updateDoc(userRef, {
      role,
      updatedAt: new Date()
    });
  }

  public async getPendingRequestsCount(locationId: string): Promise<number> {
    const getPendingRequestsCount = httpsCallable(this.functions, 'getPendingResidencyRequestsCount');
    const result = await getPendingRequestsCount({ locationId });
    return (result.data as { pendingRequestsCount: number }).pendingRequestsCount;
  }
} 