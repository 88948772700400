import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Box, 
  Paper,
  IconButton,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardMedia,
  CardActions,
  Divider,
  styled
} from '@mui/material';
import { useLocation } from '../../contexts/LocationContext';
import { useCreateLocation, useUpdateLocation } from '../../hooks/useLocationsQueries';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useAuth } from 'shared-lib';

// Стилизованный компонент для превью изображений
const ImagePreview = styled(Card)(({ theme }) => ({
  height: 200,
  position: 'relative',
  '&:hover .actions': {
    opacity: 1,
  },
}));

const ActionButtons = styled(CardActions)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,0.6)',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end',
}));

const LocationForm: React.FC = () => {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { availableLocations } = useLocation();
  const createLocation = useCreateLocation();
  const updateLocation = useUpdateLocation();
  const { user } = useAuth();
  const storage = getStorage();

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    timezone: 'Europe/Moscow',
    description: '',
  });
  
  const [images, setImages] = useState<string[]>([]);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    if (locationId) {
      const location = availableLocations.find(loc => loc.id === locationId);
      if (location) {
        setFormData({
          name: location.name,
          address: location.address,
          timezone: location.timezone,
          description: location.description || '',
        });
        
        // Загружаем изображения локации
        const locationImages = location.images || [];
        setImages(locationImages);
        setMainImage(location.mainImage || null);
      }
    }
  }, [locationId, availableLocations]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setUnsavedChanges(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (!formData.name || !formData.address || !formData.timezone) {
      setError('Пожалуйста, заполните все обязательные поля');
      return;
    }
    
    try {
      const locationData = {
        ...formData,
        images,
        mainImage  // Просто передаем как есть, включая null
      };
      
      if (locationId) {
        // Ищем существующую локацию для получения информации о владельце
        const existingLocation = availableLocations.find(loc => loc.id === locationId);
        
        if (existingLocation) {
          await updateLocation.mutateAsync({
            id: locationId,
            ...locationData,
            ownerUid: existingLocation.ownerUid,
            // mainImage будет обработан в хуке useUpdateLocation
          });
          setSuccessMessage('Локация успешно обновлена');
        } else {
          setError('Локация не найдена');
          return;
        }
      } else {
        const newLocationData = {
          ...locationData,
          isActive: true,
          ownerUid: user?.uid || '',
        };
        await createLocation.mutateAsync(newLocationData);
        setSuccessMessage('Локация успешно создана');
      }
      
      setUnsavedChanges(false);
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      console.error('Error saving location:', error);
      setError('Ошибка при сохранении локации');
    }
  };

  // Функция загрузки изображения
  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    
    setUploading(true);
    setError(null);
    
    try {
      const file = e.target.files[0];
      const locationFolder = locationId || 'new';
      const storageRef = ref(storage, `locations/${locationFolder}/${Date.now()}_${file.name}`);
      
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      
      setImages(prev => [...prev, downloadURL]);
      
      // Если это первое изображение, делаем его главным
      if (!mainImage) {
        setMainImage(downloadURL);
      }
      
      setSuccessMessage('Изображение успешно загружено');
      setUnsavedChanges(true);
    } catch (e) {
      console.error('Error uploading image:', e);
      setError('Ошибка при загрузке изображения');
    } finally {
      setUploading(false);
    }
  };

  // Функция удаления изображения
  const handleDeleteImage = async (imageUrl: string) => {
    try {
      // Получаем ссылку на изображение в хранилище
      const imageRef = ref(storage, imageUrl);
      
      // Удаляем изображение из хранилища
      await deleteObject(imageRef);
      
      // Удаляем изображение из списка
      setImages(prev => prev.filter(img => img !== imageUrl));
      
      // Если удаляется главное изображение, выбираем новое или сбрасываем
      if (mainImage === imageUrl) {
        const remainingImages = images.filter(img => img !== imageUrl);
        setMainImage(remainingImages.length > 0 ? remainingImages[0] : null);
      }
      
      setSuccessMessage('Изображение успешно удалено');
      setUnsavedChanges(true);
    } catch (e) {
      console.error('Error deleting image:', e);
      setError('Ошибка при удалении изображения');
    }
  };

  // Функция установки главного изображения
  const handleSetMainImage = (imageUrl: string) => {
    setMainImage(imageUrl);
    setSuccessMessage('Главное изображение установлено');
    setUnsavedChanges(true);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {locationId ? 'Редактирование локации' : 'Новая локация'}
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        )}
        
        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>
        )}
        
        {unsavedChanges && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            У вас есть несохраненные изменения
          </Alert>
        )}
        
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Название локации"
            name="name"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
            required
          />
          
          <TextField
            fullWidth
            label="Адрес"
            name="address"
            value={formData.address}
            onChange={handleChange}
            margin="normal"
            required
          />
          
          <TextField
            fullWidth
            label="Часовой пояс"
            name="timezone"
            value={formData.timezone}
            onChange={handleChange}
            margin="normal"
            required
            helperText="Например: Europe/Moscow, America/New_York"
          />
          
          <TextField
            fullWidth
            label="Описание"
            name="description"
            value={formData.description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          
          <Divider sx={{ my: 3 }} />
          
          <Typography variant="h6" gutterBottom>
            Изображения локации
          </Typography>
          
          <Box sx={{ mb: 3 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-image"
              type="file"
              onChange={handleImageUpload}
              disabled={uploading}
            />
            <label htmlFor="upload-image">
              <Button
                variant="outlined"
                component="span"
                startIcon={<AddPhotoAlternateIcon />}
                disabled={uploading}
              >
                {uploading ? (
                  <>
                    Загрузка... <CircularProgress size={20} sx={{ ml: 1 }} />
                  </>
                ) : (
                  'Загрузить изображение'
                )}
              </Button>
            </label>
          </Box>
          
          {/* Отображение загруженных изображений */}
          {images.length > 0 && (
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ImagePreview>
                    <CardMedia
                      component="img"
                      height="200"
                      image={imageUrl}
                      alt={`Изображение ${index + 1}`}
                    />
                    <ActionButtons className="actions">
                      {mainImage === imageUrl ? (
                        <IconButton 
                          color="warning" 
                          size="small"
                          disabled
                        >
                          <StarIcon />
                        </IconButton>
                      ) : (
                        <IconButton 
                          color="warning" 
                          size="small"
                          onClick={() => handleSetMainImage(imageUrl)}
                        >
                          <StarBorderIcon />
                        </IconButton>
                      )}
                      <IconButton 
                        color="error" 
                        size="small"
                        onClick={() => handleDeleteImage(imageUrl)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ActionButtons>
                  </ImagePreview>
                </Grid>
              ))}
            </Grid>
          )}
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button 
              variant="outlined" 
              onClick={() => navigate(-1)}
            >
              Отмена
            </Button>
            <Button 
              variant="contained" 
              type="submit"
              disabled={createLocation.isPending || updateLocation.isPending}
            >
              {createLocation.isPending || updateLocation.isPending ? (
                <>
                  Сохранение... <CircularProgress size={20} sx={{ ml: 1 }} />
                </>
              ) : (
                'Сохранить'
              )}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default LocationForm; 