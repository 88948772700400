import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { 
  Typography, 
  Container, 
  Tabs, 
  Tab, 
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  Skeleton,
  FormControlLabel,
  Switch,
  Drawer,
  IconButton,
  Menu,
} from '@mui/material';
import { useLocation } from '../contexts/LocationContext';
import { 
  collection, 
  query, 
  where, 
  getFirestore, 
  Timestamp,
  onSnapshot,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { CheckCircle, Cancel, CalendarToday, LocalOffer, Close, Edit } from '@mui/icons-material';
import { ResidentsService } from '../services/ResidentsService';
import { LocationService, Office } from 'shared-lib';
import { LocationUser } from '../types';
import { InlineEdit } from './InlineEdit';

// Типы для резидентов и запросов
interface ResidencyRequest {
  id: string;
  userId: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  locationId: string;
  status: 'pending' | 'approved' | 'rejected';
  reason: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  requestId?: string; // ID запроса в коллекции residencyRequests
}

// Вспомогательные функции
const getRoleName = (role: string): string => {
  switch (role) {
    case 'admin':
      return 'Администратор';
    case 'blocked':
      return 'Заблокирован';
    default:
      return 'Резидент';
  }
};

const getRoleColor = (role: string): string => {
  switch (role) {
    case 'admin':
      return 'primary';
    case 'blocked':
      return 'error';
    default:
      return 'default';
  }
};

// Компонент управления резидентами
const Residents = (): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);
  const { currentLocation } = useLocation();
  const [locationUsers, setLocationUsers] = useState<LocationUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [offices, setOffices] = useState<Office[]>([]);
  const initialLoadCompleted = useRef(false);
  
  // Состояние для диалогов
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<ResidencyRequest | null>(null);
  const [selectedUser, setSelectedUser] = useState<LocationUser | null>(null);
  const [dialogAction, setDialogAction] = useState<'approve' | 'reject' | 'block' | 'unblock' | 'change_role' | 'remove' | 'set_discount'>('approve');
  const [rejectReason, setRejectReason] = useState('');
  const [newRole, setNewRole] = useState<'resident' | 'admin' | 'blocked'>('resident');
  
  // Состояние для drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const db = getFirestore();
  const residentsService = ResidentsService.getInstance();
  const locationService = LocationService.getInstance();
  
  // Получаем всех пользователей локации одним запросом
  useEffect(() => {
    if (!currentLocation) return;

    setLoading(true);
    initialLoadCompleted.current = false;
    const usersQuery = query(
      collection(db, 'locationUsers'),
      where('locationId', '==', currentLocation.id)
    );

    const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
      const usersData: LocationUser[] = [];
      
      for (const userDoc of snapshot.docs) {
        // Явное преобразование типа с необходимыми полями
        const userData = userDoc.data() as LocationUser & {
          requestId?: string;
          reason?: string;
          createdAt?: Timestamp;
          updatedAt?: Timestamp;
        };
        
        // Форматируем имя пользователя, если нужно
        let userName = userData.userName;
        if (!userName) {
          if (userData.firstName && userData.lastName) {
            userName = `${userData.firstName} ${userData.lastName}`;
          } else if (userData.displayName) {
            userName = userData.displayName;
          } else {
            userName = `Пользователь ${userData.userId.substr(0, 5)}`;
          }
        }
        
        usersData.push({
          ...userData,
          id: userDoc.id,
          userName
        });
      }
      
      setLocationUsers(usersData);
      setLoading(false);
      
      // Автоматически переключаемся на вкладку с запросами, только при первой загрузке
      if (!initialLoadCompleted.current) {
        initialLoadCompleted.current = true;
        const pendingRequests = usersData.filter(user => user.role === 'pending');
        if (pendingRequests.length > 0) {
          setTabValue(1); // Запросы
        } else {
          setTabValue(0); // Резиденты (если нет запросов)
        }
      }
    }, (error) => {
      console.error('Error listening to location users:', error);
      setError('Ошибка при получении данных о пользователях локации');
      setLoading(false);
      initialLoadCompleted.current = true;
    });

    return () => unsubscribe();
  }, [currentLocation, db]);

  // Загружаем офисы для скидок
  useEffect(() => {
    if (!currentLocation) return;

    const loadOffices = async () => {
      try {
        const fetchedOffices = await locationService.fetchOfficesForLocation(currentLocation.id, false);
        if (Array.isArray(fetchedOffices)) {
          setOffices(fetchedOffices);
        }
      } catch (error) {
        console.error('Error loading offices:', error);
      }
    };

    loadOffices();
  }, [currentLocation, locationService]);
  
  // Функция форматирования даты
  const formatDate = useCallback((timestamp: Timestamp) => {
    if (!timestamp) return 'Неизвестно';
    return new Date(timestamp.seconds * 1000).toLocaleString();
  }, []);

  // Разделяем пользователей на категории
  const pendingRequests = useMemo(() => {
    return locationUsers
      // @ts-ignore - Типы будут доступны после обновления компилятора
      .filter(user => user.role === 'pending')
      .map(user => {
        // Используем явное приведение типа
        const typedUser = user as LocationUser & {
          reason?: string;
          createdAt?: Timestamp;
          updatedAt?: Timestamp;
          requestId?: string;
        };
        
        return {
          id: typedUser.id,
          userId: typedUser.userId,
          userName: typedUser.userName,
          firstName: typedUser.firstName,
          lastName: typedUser.lastName,
          displayName: typedUser.displayName,
          locationId: typedUser.locationId,
          status: 'pending' as const,
          reason: typedUser.reason || 'Не указана',
          createdAt: typedUser.createdAt || Timestamp.now(),
          updatedAt: typedUser.updatedAt || Timestamp.now(),
          requestId: typedUser.requestId
        };
      });
  }, [locationUsers]);

  const activeResidents = useMemo(() => {
    // @ts-ignore - Типы будут доступны после обновления компилятора
    return locationUsers.filter(user => user.role === 'resident' || user.role === 'admin');
  }, [locationUsers]);

  const blockedUsers = useMemo(() => {
    // @ts-ignore - Типы будут доступны после обновления компилятора
    return locationUsers.filter(user => user.role === 'blocked');
  }, [locationUsers]);

  // Функция для проверки наличия активных скидок в текущей локации
  const hasActiveDiscounts = useCallback((resident: LocationUser): boolean => {
    if (!resident.officeDiscounts || !offices.length) return false;
    
    // Фильтруем скидки только для офисов текущей локации
    return Object.entries(resident.officeDiscounts).some(
      ([officeId, discount]) => 
        // Проверяем, существует ли офис в текущей локации
        offices.some(office => office.id === officeId) && 
        // Проверяем, что скидка ненулевая или бесплатное бронирование
        (discount.discount > 0 || discount.isFree === true)
    );
  }, [offices]);

  // Функция для обработки запроса на резидентство
  const handleRequestAction = useCallback(async () => {
    if (!selectedRequest || !currentLocation) return;
    
    try {
      if (dialogAction === 'approve') {
        // Используем сервис для одобрения запроса
        const result = await residentsService.approveResidencyRequest(
          currentLocation.id, 
          selectedRequest.requestId || selectedRequest.id,
          newRole as 'resident' | 'admin'
        );
        
        if (result.success) {
          setSuccess(result.message);
        } else {
          setError('Ошибка при обработке запроса');
        }
      } else if (dialogAction === 'reject') {
        // Используем сервис для отклонения запроса
        const result = await residentsService.rejectResidencyRequest(
          currentLocation.id, 
          selectedRequest.requestId || selectedRequest.id,
          rejectReason
        );
        
        if (result.success) {
          setSuccess(result.message);
        } else {
          setError('Ошибка при обработке запроса');
        }
      }
    } catch (e) {
      console.error('Error processing request:', e);
      setError('Ошибка при обработке запроса');
    } finally {
      setOpenRequestDialog(false);
      setSelectedRequest(null);
      setRejectReason('');
    }
  }, [selectedRequest, currentLocation, dialogAction, newRole, rejectReason, residentsService]);
  
  // Функция удаления пользователя из списка резидентов
  const handleRemoveUser = useCallback(async (userId: string) => {
    if (!currentLocation) return;
    
    try {
      const result = await residentsService.removeUser(
        currentLocation.id,
        userId
      );
      
      if (result.success) {
        setSuccess(result.message);
      } else {
        setError('Ошибка при удалении пользователя');
      }
    } catch (e) {
      console.error('Error removing user:', e);
      setError('Ошибка при удалении пользователя');
    }
  }, [currentLocation, residentsService]);

  // Функция управления резидентом
  const handleUserAction = useCallback(async () => {
    if (!selectedUser || !currentLocation) return;
    
    try {
      if (dialogAction === 'remove') {
        // Используем сервис для удаления пользователя
        const result = await residentsService.removeUser(
          currentLocation.id, 
          selectedUser.userId
        );
        
        if (result.success) {
          // Удаляем только конкретного резидента из списка
          setLocationUsers(prevUsers => 
            prevUsers.filter(u => u.userId !== selectedUser.userId)
          );
          setSuccess(result.message);
        } else {
          setError('Ошибка при удалении пользователя');
        }
      } else {
        // Для других действий используем соответствующие методы сервиса
        if (dialogAction === 'block') {
          await residentsService.updateUserRole(
            currentLocation.id,
            selectedUser.userId,
            'blocked'
          );
          // Обновляем только конкретного резидента в списке
          setLocationUsers(prevUsers => 
            prevUsers.map(u => 
              u.id === selectedUser.id 
                ? { ...u, role: 'blocked' }
                : u
            )
          );
          setSuccess('Пользователь заблокирован');
        } else if (dialogAction === 'unblock') {
          await residentsService.updateUserRole(
            currentLocation.id,
            selectedUser.userId,
            'resident'
          );
          // Обновляем только конкретного резидента в списке
          setLocationUsers(prevUsers => 
            prevUsers.map(u => 
              u.id === selectedUser.id 
                ? { ...u, role: 'resident' }
                : u
            )
          );
          setSuccess('Пользователь разблокирован');
        } else if (dialogAction === 'change_role') {
          await residentsService.updateUserRole(
            currentLocation.id,
            selectedUser.userId,
            newRole
          );
          // Обновляем только конкретного резидента в списке
          setLocationUsers(prevUsers => 
            prevUsers.map(u => 
              u.id === selectedUser.id 
                ? { ...u, role: newRole }
                : u
            )
          );
          setSuccess(`Роль пользователя изменена на: ${getRoleName(newRole)}`);
        }
      }
    } catch (e) {
      console.error('Error managing user:', e);
      setError('Ошибка при управлении пользователем');
    } finally {
      setOpenUserDialog(false);
      setOpenRemoveDialog(false);
      setSelectedUser(null);
    }
  }, [selectedUser, currentLocation, dialogAction, newRole, residentsService]);

  // Функция для сохранения всех изменений
  const handleSaveAll = useCallback(async () => {
    if (!selectedUser || !currentLocation) return;

    try {
      const userDocId = `${selectedUser.userId}_${currentLocation.id}`;
      const userRef = doc(db, 'locationUsers', userDocId);
      
      // Фильтруем только офисы, которые существуют в текущей локации
      const validOfficeDiscounts = Object.entries(selectedUser.officeDiscounts || {}).reduce((acc, [officeId, discount]) => {
        if (offices.some(office => office.id === officeId)) {
          acc[officeId] = discount;
        }
        return acc;
      }, {} as Record<string, { discount: number; isFree: boolean }>);

      await updateDoc(userRef, {
        officeDiscounts: validOfficeDiscounts,
        updatedAt: new Date()
      });

      // Обновляем только конкретного резидента в списке
      setLocationUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === selectedUser.id 
            ? { ...u, officeDiscounts: validOfficeDiscounts }
            : u
        )
      );

      setSuccess('Скидки успешно сохранены');
      setOpenDiscountDialog(false);
      setSelectedUser(null);
    } catch (error) {
      console.error('Error saving discounts:', error);
      setError('Ошибка при сохранении скидок');
    }
  }, [selectedUser, currentLocation, offices, db]);

  // Обновляем функцию для автоматического сохранения изменений
  const handleOfficeDiscountChange = useCallback(async (
    resident: LocationUser,
    officeId: string, 
    isFree: boolean, 
    discount: number
  ) => {
    if (!currentLocation) return;

    try {
      const userDocId = `${resident.userId}_${currentLocation.id}`;
      const userRef = doc(db, 'locationUsers', userDocId);
      
      const updatedDiscounts = {
        ...resident.officeDiscounts,
        [officeId]: {
          discount: isFree ? 100 : discount,
          isFree
        }
      };
      
      if (discount === 0) {
        delete updatedDiscounts[officeId];
      }

      await updateDoc(userRef, {
        officeDiscounts: updatedDiscounts,
        updatedAt: new Date()
      });

      // Обновляем только конкретного резидента в списке
      setLocationUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === resident.id 
            ? { ...u, officeDiscounts: updatedDiscounts }
            : u
        )
      );
    } catch (error) {
      console.error('Error saving discount:', error);
      setError('Ошибка при сохранении скидки');
    }
  }, [currentLocation, db]);

  // Функция для обновления роли пользователя
  const handleRoleChange = useCallback(async (resident: LocationUser, newRole: 'resident' | 'admin' | 'blocked') => {
    if (!currentLocation) return;

    try {
      await residentsService.updateUserRole(
        currentLocation.id,
        resident.userId,
        newRole
      );
      
      // Обновляем только конкретного резидента в списке
      setLocationUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === resident.id 
            ? { ...u, role: newRole }
            : u
        )
      );
      
      setSuccess(`Роль пользователя изменена на: ${getRoleName(newRole)}`);
    } catch (error) {
      console.error('Error updating user role:', error);
      setError('Ошибка при изменении роли пользователя');
    }
  }, [currentLocation, residentsService]);

  // Функция для обновления имени пользователя
  const handleNameChange = useCallback(async (resident: LocationUser, newName: string) => {
    if (!currentLocation) return;

    try {
      const userDocId = `${resident.userId}_${currentLocation.id}`;
      const userRef = doc(db, 'locationUsers', userDocId);
      
      await updateDoc(userRef, {
        userName: newName,
        updatedAt: new Date()
      });
      
      // Обновляем только конкретного резидента в списке
      setLocationUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === resident.id 
            ? { ...u, userName: newName }
            : u
        )
      );
      
      setSuccess('Имя пользователя обновлено');
    } catch (error) {
      console.error('Error updating user name:', error);
      setError('Ошибка при обновлении имени пользователя');
    }
  }, [currentLocation, db]);

  // Компонент карточки резидента
  const ResidentCard = React.memo(({ 
    resident, 
    onNameChange, 
    onRoleChange, 
    onRemove, 
    onOpenDiscountDrawer,
    hasSpecialConditions
  }: {
    resident: LocationUser;
    onNameChange: (resident: LocationUser, newName: string) => Promise<void>;
    onRoleChange: (resident: LocationUser, newRole: 'resident' | 'admin' | 'blocked') => Promise<void>;
    onRemove: (resident: LocationUser) => void;
    onOpenDiscountDrawer: (resident: LocationUser) => void;
    hasSpecialConditions: (resident: LocationUser) => boolean;
  }) => {
    const [roleMenuAnchor, setRoleMenuAnchor] = useState<{
      element: HTMLElement;
      resident: LocationUser;
    } | null>(null);

    const handleRoleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
      const target = event.currentTarget;
      if (target instanceof HTMLElement) {
        setRoleMenuAnchor({ element: target, resident });
      }
    }, [resident]);

    const handleRoleMenuClose = useCallback(() => {
      setRoleMenuAnchor(null);
    }, []);

    const handleRoleSelect = useCallback((newRole: 'resident' | 'admin' | 'blocked') => {
      if (roleMenuAnchor) {
        onRoleChange(roleMenuAnchor.resident, newRole);
        setRoleMenuAnchor(null);
      }
    }, [roleMenuAnchor, onRoleChange]);

    const handleRemoveClick = useCallback(() => {
      onRemove(resident);
    }, [resident, onRemove]);

    const handleDiscountClick = useCallback(() => {
      onOpenDiscountDrawer(resident);
    }, [resident, onOpenDiscountDrawer]);

    const handleNameSave = useCallback((newName: string) => {
      return onNameChange(resident, newName);
    }, [resident, onNameChange]);

    return (
      <Card variant="outlined" sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <InlineEdit
              value={resident.userName || 'Неизвестно'}
              onSave={handleNameSave}
            />
            <Box 
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              <Chip
                size="small"
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    {getRoleName(resident.role)}
                    <Edit sx={{ fontSize: 14, opacity: 0.7 }} />
                  </Box>
                }
                color={getRoleColor(resident.role) as any}
                onClick={handleRoleMenuClick}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                    transform: 'scale(1.02)',
                    transition: 'all 0.2s ease-in-out',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  },
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5
                  }
                }}
              />
              <IconButton
                size="small"
                color="error"
                onClick={handleRemoveClick}
              >
                <Cancel fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Typography 
            component="span"
            color={hasSpecialConditions(resident) ? "primary" : "text.secondary"}
            sx={{ 
              cursor: 'pointer',
              fontSize: '0.875rem',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={handleDiscountClick}
          >
            <LocalOffer fontSize="small" sx={{ fontSize: '1rem' }} />
            {hasSpecialConditions(resident) ? 'Спецусловия' : 'Настроить'}
          </Typography>
        </Box>

        <Menu
          anchorEl={roleMenuAnchor?.element}
          open={Boolean(roleMenuAnchor)}
          onClose={handleRoleMenuClose}
        >
          <MenuItem 
            onClick={() => handleRoleSelect('resident')}
            selected={roleMenuAnchor?.resident.role === 'resident'}
          >
            Резидент
          </MenuItem>
          <MenuItem 
            onClick={() => handleRoleSelect('admin')}
            selected={roleMenuAnchor?.resident.role === 'admin'}
          >
            Администратор
          </MenuItem>
          <Divider />
          <MenuItem 
            onClick={() => handleRoleSelect('blocked')}
            selected={roleMenuAnchor?.resident.role === 'blocked'}
            sx={{ color: 'error.main' }}
          >
            Заблокирован
          </MenuItem>
        </Menu>
      </Card>
    );
  });

  // Рендеринг вкладки запросов
  const renderRequestsTab = () => {
    return (
      <div>
        {loading ? (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {[1, 2, 3].map((i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Card variant="outlined">
                  <CardContent>
                    <Skeleton variant="text" width="60%" height={32} />
                    <Divider sx={{ my: 1 }} />
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="40%" />
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'space-between', padding: '8px 16px 16px' }}>
                    <Skeleton variant="rectangular" width={36} height={36} />
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : error ? (
          <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
        ) : pendingRequests.length === 0 ? (
          <Alert severity="info" sx={{ mt: 2 }}>Нет запросов на резидентство</Alert>
        ) : (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {pendingRequests.map((request) => (
              <Grid item xs={12} sm={6} md={4} key={request.id}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      {request.userName || 'Неизвестно'}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      <strong>Цель:</strong> {request.reason || 'Не указана'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" display="block">
                      <CalendarToday fontSize="small" sx={{ mr: 0.5, fontSize: '0.875rem', verticalAlign: 'middle' }} />
                      Дата запроса: {request.createdAt ? formatDate(request.createdAt) : 'Неизвестно'}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'space-between', padding: '8px 16px 16px' }}>
                    <Button 
                      size="small"
                      variant="contained"
                      color="success"
                      sx={{ minWidth: '30px' }}
                      onClick={() => {
                        setSelectedRequest(request);
                        setDialogAction('approve');
                        setNewRole('resident');
                        setOpenRequestDialog(true);
                      }}
                    >
                      <CheckCircle fontSize="small" />
                    </Button>
                    <Button 
                      size="small"
                      variant="outlined"
                      color="error"
                      sx={{ minWidth: '30px' }}
                      onClick={() => {
                        setSelectedRequest(request);
                        setDialogAction('reject');
                        setRejectReason('');
                        setOpenRequestDialog(true);
                      }}
                    >
                      <Cancel fontSize="small" />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };

  // Рендеринг вкладки резидентов
  const renderResidentsTab = () => {
    return (
      <div>
        {loading ? (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {[1, 2, 3].map((i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Card variant="outlined">
                  <CardContent>
                    <Skeleton variant="text" width="60%" height={32} />
                    <Skeleton variant="text" width="40%" height={24} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : error ? (
          <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
        ) : activeResidents.length === 0 ? (
          <Alert severity="info" sx={{ mt: 2 }}>Нет активных резидентов</Alert>
        ) : (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {activeResidents.map((resident: LocationUser) => (
              <Grid item xs={12} sm={6} md={4} key={resident.id}>
                <ResidentCard
                  resident={resident}
                  onNameChange={handleNameChange}
                  onRoleChange={handleRoleChange}
                  onRemove={(resident) => {
                    setSelectedUser(resident);
                    setDialogAction('remove');
                    setOpenRemoveDialog(true);
                  }}
                  onOpenDiscountDrawer={(resident) => {
                    if (!offices.length) {
                      loadOffices(currentLocation?.id);
                    }
                    setSelectedUser(resident);
                    setDrawerOpen(true);
                  }}
                  hasSpecialConditions={hasActiveDiscounts}
                />

                {selectedUser?.id === resident.id && (
                  <DiscountDrawer
                    open={drawerOpen}
                    onClose={() => {
                      setDrawerOpen(false);
                      setSelectedUser(null);
                    }}
                    discounts={resident.officeDiscounts || {}}
                    offices={offices}
                    onSave={(updatedDiscounts) => handleSaveDiscounts(resident, updatedDiscounts)}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };
  
  // Рендеринг вкладки заблокированных пользователей
  const renderBlockedTab = () => {
    return (
      <div>
        {loading ? (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {[1, 2, 3].map((i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Card variant="outlined">
                  <CardContent>
                    <Skeleton variant="text" width="60%" height={32} />
                    <Skeleton variant="text" width="40%" height={24} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : error ? (
          <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
        ) : blockedUsers.length === 0 ? (
          <Alert severity="info" sx={{ mt: 2 }}>Нет заблокированных пользователей</Alert>
        ) : (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {blockedUsers.map((resident: LocationUser) => {
              // Используем явное приведение типа
              const typedResident = resident as LocationUser & { rejectionReason?: string };
              
              return (
                <Grid item xs={12} sm={6} md={4} key={resident.id}>
                  <Card variant="outlined" sx={{ p: 1, bgcolor: 'rgba(244, 67, 54, 0.05)' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography component="div">{resident.userName || 'Неизвестно'}</Typography>
                        <Box 
                          sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                        >
                          <Chip
                            size="small"
                            label={getRoleName(resident.role)}
                            color={getRoleColor(resident.role) as any}
                          />
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              handleRoleChange(resident, 'resident');
                            }}
                            title="Разблокировать"
                          >
                            <CheckCircle fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => {
                              setSelectedUser(resident);
                              setDialogAction('remove');
                              setOpenRemoveDialog(true);
                            }}
                            title="Удалить пользователя"
                          >
                            <Cancel fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                      {typedResident.rejectionReason && (
                        <Typography variant="caption" color="text.secondary">
                          Причина: {typedResident.rejectionReason}
                        </Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </div>
    );
  };

  // Добавляем функцию загрузки офисов
  const loadOffices = useCallback(async (locationId?: string) => {
    if (!locationId) return;

    try {
      console.log('Starting to fetch offices for location:', locationId);
      const fetchedOffices = await locationService.fetchOfficesForLocation(locationId, false);
      console.log('Fetched offices:', fetchedOffices);
      
      if (!Array.isArray(fetchedOffices)) {
        console.error('Offices is not an array:', fetchedOffices);
        setError('Некорректный формат данных офисов');
        return;
      }

      if (fetchedOffices.length === 0) {
        console.log('No offices found for location:', locationId);
        setError('Нет доступных офисов для этой локации');
        return;
      }

      setOffices(fetchedOffices);
    } catch (error) {
      console.error('Error loading offices:', error);
      setError('Ошибка при загрузке списка офисов');
    }
  }, [locationService]);

  // Компонент для drawer со скидками
  const DiscountDrawer = React.memo(({ 
    open, 
    onClose, 
    discounts, 
    offices, 
    onSave 
  }: {
    open: boolean;
    onClose: () => void;
    discounts: {
      [officeId: string]: { discount: number; isFree: boolean }
    };
    offices: Office[];
    onSave: (updatedDiscounts: {
      [officeId: string]: { discount: number; isFree: boolean }
    }) => void;
  }): JSX.Element => {
    // Локальное состояние для хранения и редактирования скидок
    const [localDiscounts, setLocalDiscounts] = useState<{
      [officeId: string]: { discount: number; isFree: boolean }
    }>({});

    // Инициализация при открытии
    useEffect(() => {
      if (open) {
        // Глубокое копирование объекта скидок
        setLocalDiscounts(JSON.parse(JSON.stringify(discounts || {})));
      }
    }, [open, discounts]);

    // Функция для обновления скидки для конкретного офиса
    const handleDiscountChange = (officeId: string, isFree: boolean, discount: number) => {
      setLocalDiscounts(prev => ({
        ...prev,
        [officeId]: { discount, isFree }
      }));
    };

    // Сохраняем все изменения при закрытии
    const handleSave = () => {
      onSave(localDiscounts);
      onClose();
    };

    return (
      <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            maxHeight: '80vh'
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" sx={{ fontSize: '1.125rem' }}>
              Скидки
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>

          <Box sx={{ mb: 3 }}>
            {offices.map((office) => {
              const officeDiscount = localDiscounts[office.id] || { discount: 0, isFree: false };
              const displayValue = officeDiscount.isFree ? 'free' : officeDiscount.discount;
              
              return (
                <Box 
                  key={office.id} 
                  sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    py: 1.5,
                    gap: 2,
                    '&:not(:last-child)': {
                      borderBottom: '1px solid',
                      borderColor: 'divider'
                    }
                  }}
                >
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      fontSize: '0.875rem',
                      flex: 1,
                      pt: 0.75
                    }}
                  >
                    {office.name}
                  </Typography>
                  <Select
                    size="small"
                    value={displayValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === 'free') {
                        handleDiscountChange(office.id, true, 100);
                      } else {
                        handleDiscountChange(office.id, false, Number(value));
                      }
                    }}
                    sx={{ 
                      width: 200,
                      flexShrink: 0,
                      '& .MuiSelect-select': {
                        py: 1,
                        fontSize: '0.875rem'
                      }
                    }}
                  >
                    <MenuItem value="free">Бесплатно (100%)</MenuItem>
                    <MenuItem value={0}>Без скидки (0%)</MenuItem>
                    <Divider />
                    {Array.from({ length: 19 }, (_, i) => (i + 1) * 5).map((value) => (
                      <MenuItem key={value} value={value}>
                        Скидка {value}%
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              );
            })}
          </Box>

          <Box sx={{ 
            position: 'sticky', 
            bottom: 0, 
            bgcolor: 'background.paper',
            pt: 2,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}>
            <Button 
              fullWidth 
              variant="contained" 
              onClick={handleSave}
            >
              Сохранить и закрыть
            </Button>
          </Box>
        </Box>
      </Drawer>
    );
  });

  // Новая функция для сохранения всех скидок разом
  const handleSaveDiscounts = useCallback(async (
    resident: LocationUser,
    updatedDiscounts: { [officeId: string]: { discount: number; isFree: boolean } }
  ) => {
    if (!currentLocation) return;

    try {
      const userDocId = `${resident.userId}_${currentLocation.id}`;
      const userRef = doc(db, 'locationUsers', userDocId);
      
      await updateDoc(userRef, {
        officeDiscounts: updatedDiscounts,
        updatedAt: new Date()
      });

      // Обновляем только конкретного резидента в списке
      setLocationUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === resident.id 
            ? { ...u, officeDiscounts: updatedDiscounts }
            : u
        )
      );

      setSuccess('Скидки успешно сохранены');
    } catch (error) {
      console.error('Error saving discounts:', error);
      setError('Ошибка при сохранении скидок');
    }
  }, [currentLocation, db]);

  if (!currentLocation) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography>Выберите локацию для управления резидентами</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Резиденты локации: {currentLocation.name}
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>{error}</Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>{success}</Alert>
      )}
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
          <Tab 
            label={
              loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Резиденты
                  <Skeleton variant="text" width={30} />
                </Box>
              ) : (
                `Резиденты (${activeResidents.length})`
              )
            } 
          />
          <Tab 
            label={
              loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Запросы
                  <Skeleton variant="text" width={30} />
                </Box>
              ) : (
                `Запросы (${pendingRequests.length})`
              )
            } 
          />
          <Tab 
            label={
              loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Заблокированные
                  <Skeleton variant="text" width={30} />
                </Box>
              ) : (
                `Заблокированные (${blockedUsers.length})`
              )
            } 
          />
        </Tabs>
      </Box>
      
      <Box>
        {tabValue === 0 && renderResidentsTab()}
        {tabValue === 1 && renderRequestsTab()}
        {tabValue === 2 && renderBlockedTab()}
      </Box>
      
      {/* Диалог для обработки запросов */}
      <Dialog open={openRequestDialog} onClose={() => setOpenRequestDialog(false)}>
        <DialogTitle>
          {dialogAction === 'approve' ? 'Одобрить запрос на резидентство' : 'Отклонить запрос на резидентство'}
        </DialogTitle>
        <DialogContent>
          {dialogAction === 'approve' ? (
            <>
              <DialogContentText>
                Вы собираетесь одобрить запрос на резидентство от пользователя {selectedRequest?.userName || 'Неизвестный пользователь'}.
                Выберите роль для нового резидента:
              </DialogContentText>
              <FormControl fullWidth margin="normal">
                <InputLabel>Роль пользователя</InputLabel>
                <Select
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value as any)}
                  label="Роль пользователя"
                >
                  <MenuItem value="resident">Резидент</MenuItem>
                  <MenuItem value="admin">Администратор</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : (
            <>
              <DialogContentText>
                Вы собираетесь отклонить запрос на резидентство от пользователя {selectedRequest?.userName || 'Неизвестный пользователь'}.
                Пожалуйста, укажите причину отказа:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Укажите причину отказа"
                fullWidth
                multiline
                rows={3}
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRequestDialog(false)}>Отмена</Button>
          <Button 
            onClick={handleRequestAction} 
            color={dialogAction === 'approve' ? 'primary' : 'error'}
            variant="contained"
            disabled={dialogAction === 'reject' && !rejectReason.trim()}
          >
            {dialogAction === 'approve' ? 'Одобрить' : 'Отклонить'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Диалог для управления резидентами */}
      <Dialog open={openUserDialog} onClose={() => setOpenUserDialog(false)}>
        <DialogTitle>
          {dialogAction === 'change_role' ? 'Изменить роль пользователя' : 
           dialogAction === 'block' ? 'Заблокировать пользователя' : 'Разблокировать пользователя'}
        </DialogTitle>
        <DialogContent>
          {dialogAction === 'change_role' ? (
            <>
              <DialogContentText>
                Выберите новую роль для пользователя {selectedUser?.userName || 'Неизвестный пользователь'}:
              </DialogContentText>
              <FormControl fullWidth margin="normal">
                <InputLabel>Роль пользователя</InputLabel>
                <Select
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value as any)}
                  label="Роль пользователя"
                >
                  <MenuItem value="resident">Резидент</MenuItem>
                  <MenuItem value="admin">Администратор</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : (
            <DialogContentText>
              {dialogAction === 'block' 
                ? `Вы собираетесь заблокировать пользователя ${selectedUser?.userName || 'Неизвестный пользователь'}. Он не сможет бронировать офисы в этой локации.`
                : `Вы собираетесь разблокировать пользователя ${selectedUser?.userName || 'Неизвестный пользователь'}. Он снова сможет бронировать офисы в этой локации.`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUserDialog(false)}>Отмена</Button>
          <Button 
            onClick={handleUserAction} 
            color={dialogAction === 'block' ? 'error' : 'primary'}
            variant="contained"
          >
            {dialogAction === 'change_role' ? 'Изменить роль' : 
             dialogAction === 'block' ? 'Заблокировать' : 'Разблокировать'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Диалог для удаления резидента */}
      <Dialog open={openRemoveDialog} onClose={() => setOpenRemoveDialog(false)}>
        <DialogTitle>Удалить резидента</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы собираетесь удалить пользователя {selectedUser?.userName || 'Неизвестный пользователь'} из резидентов локации.
            Это действие нельзя отменить. Продолжить?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemoveDialog(false)}>Отмена</Button>
          <Button 
            onClick={() => {
              if (selectedUser?.userId) {
                handleRemoveUser(selectedUser.userId);
                setOpenRemoveDialog(false);
                setSelectedUser(null);
              }
            }}
            color="error"
            variant="contained"
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог настройки скидки */}
      <Dialog 
        open={openDiscountDialog} 
        onClose={() => setOpenDiscountDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Настройка скидок для резидента {selectedUser?.userName || 'Неизвестный пользователь'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Установите скидки для каждого офиса. Если скидка не установлена, будет использоваться стандартная цена.
          </DialogContentText>
          <Grid container spacing={2}>
            {offices.map((office) => {
              const officeDiscount = selectedUser?.officeDiscounts?.[office.id] || { discount: 0, isFree: false };
              return (
                <Grid item xs={12} key={office.id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {office.name}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={officeDiscount.isFree}
                              onChange={(e) => handleOfficeDiscountChange(selectedUser!, office.id, e.target.checked, e.target.checked ? 100 : officeDiscount.discount)}
                            />
                          }
                          label="Бесплатное бронирование"
                        />
                        {!officeDiscount.isFree && (
                          <TextField
                            label="Скидка (%)"
                            type="number"
                            value={officeDiscount.discount}
                            onChange={(e) => handleOfficeDiscountChange(selectedUser!, office.id, officeDiscount.isFree, Number(e.target.value))}
                            size="small"
                            sx={{ width: 150 }}
                            InputProps={{
                              inputProps: { min: 0, max: 100 }
                            }}
                          />
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDiscountDialog(false)}>Отмена</Button>
          <Button onClick={handleSaveAll} variant="contained" color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Residents; 