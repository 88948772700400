import { useQuery } from '@tanstack/react-query';
import { collection, query, where, getDocs, Timestamp, DocumentData, getDoc, doc } from 'firebase/firestore';
import { db } from 'shared-lib';
import { startOfMonth, endOfMonth, subMonths, format } from 'date-fns';
import { PaymentMethod } from 'shared-lib';
import { ru } from 'date-fns/locale';

// Обновленные значения для периодов: текущий месяц и три предыдущих
export type Period = 'currentMonth' | 'month1' | 'month2' | 'month3';

// Структура фильтров для финансового отчета
export interface FinanceFilters {
  period: Period;
  officeId?: string;
  clientId?: string;
}

export interface Booking extends DocumentData {
  id: string;
  startTime: Date;
  endTime: Date;
  createdAt: Date;
  price: number;
  status: string;
  locationId: string;
  uid: string;
  officeId: string;
  officeName?: string;
  customer_first_name: string;
  customer_last_name: string;
  payment?: {
    status: string;
    amount: number;
    method?: typeof PaymentMethod[keyof typeof PaymentMethod];
    paymentId?: string;
    sbpUrl?: string;
    receiptSent?: boolean;
    receiptSentAt?: string;
  };
}

// Интерфейс для клиента (для фильтрации)
export interface Client {
  id: string; // используем uid клиента как id
  fullName: string;
}

// Интерфейс для офиса (для фильтрации)
export interface Office {
  id: string;
  name: string;
}

// Функция для получения названия месяца на русском
export const getPeriodLabel = (period: Period): string => {
  const now = new Date();
  
  switch (period) {
    case 'currentMonth':
      return format(now, 'LLLL yyyy', { locale: ru });
    case 'month1':
      return format(subMonths(now, 1), 'LLLL yyyy', { locale: ru });
    case 'month2':
      return format(subMonths(now, 2), 'LLLL yyyy', { locale: ru });
    case 'month3':
      return format(subMonths(now, 3), 'LLLL yyyy', { locale: ru });
    default:
      return 'Неизвестный период';
  }
};

const getDateRangeForPeriod = (period: Period) => {
  const now = new Date();
  
  // Проверка диапазона дат по отладочным причинам
  const debugDate = (date: Date): string => {
    return date.toISOString().split('T')[0]; // формат YYYY-MM-DD
  };
  
  let result;
  
  switch (period) {
    case 'currentMonth':
      result = {
        start: startOfMonth(now),
        end: endOfMonth(now)
      };
      break;
    case 'month1': // Предыдущий месяц
      const lastMonth = subMonths(now, 1);
      result = {
        start: startOfMonth(lastMonth),
        end: endOfMonth(lastMonth)
      };
      break;
    case 'month2': // Два месяца назад
      const twoMonthsAgo = subMonths(now, 2);
      result = {
        start: startOfMonth(twoMonthsAgo),
        end: endOfMonth(twoMonthsAgo)
      };
      break;
    case 'month3': // Три месяца назад
      const threeMonthsAgo = subMonths(now, 3);
      result = {
        start: startOfMonth(threeMonthsAgo),
        end: endOfMonth(threeMonthsAgo)
      };
      break;
    default:
      result = {
        start: startOfMonth(now),
        end: endOfMonth(now)
      };
  }
  
  // Устанавливаем время для начальной и конечной даты
  result.start.setHours(0, 0, 0, 0);
  result.end.setHours(23, 59, 59, 999);
  
  console.log(`Период ${period}: с ${debugDate(result.start)} по ${debugDate(result.end)}`);
  
  return result;
};

const convertTimestampToDate = (timestamp: Timestamp | undefined | null): Date => {
  if (!timestamp || !(timestamp instanceof Timestamp)) {
    console.warn('Invalid timestamp:', timestamp);
    return new Date(0);
  }
  return timestamp.toDate();
};

export const useFinanceBookings = (
  locationId: string | undefined, 
  filters: FinanceFilters
) => {
  return useQuery<Booking[]>({
    queryKey: ['financeBookings', locationId, filters],
    queryFn: async () => {
      if (!locationId) {
        return [];
      }

      try {
        const { start, end } = getDateRangeForPeriod(filters.period);
        const bookingsRef = collection(db, 'bookings');
        
        // Преобразуем JavaScript Date в Firestore Timestamp
        const startTimestamp = Timestamp.fromDate(start);
        const endTimestamp = Timestamp.fromDate(end);
        
        console.log(`Фильтрация с параметрами:`, {
          период: filters.period,
          офис: filters.officeId || 'все',
          клиент: filters.clientId || 'все',
          startDate: start.toISOString(),
          endDate: end.toISOString(),
        });
        
        // Сначала попробуем использовать фильтр по startTime для точной выборки
        let bookingsSnapshot;
        
        try {
          // Создаем базовый запрос
          let baseQuery = query(
            bookingsRef,
            where('locationId', '==', locationId),
            where('startTime', '>=', startTimestamp),
            where('startTime', '<=', endTimestamp)
          );
          
          bookingsSnapshot = await getDocs(baseQuery);
          console.log(`Получено ${bookingsSnapshot.size} бронирований по фильтру периода`);
        } catch (error) {
          console.warn("Ошибка при фильтрации по периоду:", error);
          console.log("Используем запрос только по локации...");
          
          // Если не сработало, используем только фильтр по локации
          const locationQuery = query(
            bookingsRef,
            where('locationId', '==', locationId)
          );
          
          bookingsSnapshot = await getDocs(locationQuery);
          console.log(`Получено ${bookingsSnapshot.size} бронирований для локации без фильтра по дате`);
        }
        
        // Собираем статистику по датам для отладки
        const bookingDateStats: {
          allDates: string[];
          byMonth: Record<string, number>;
          invalid: number;
        } = {
          allDates: [],
          byMonth: {},
          invalid: 0
        };
        
        // Сначала преобразуем все бронирования
        const allBookings: Booking[] = [];
        
        bookingsSnapshot.docs.forEach(doc => {
          const data = doc.data();
          const rawStartTime = data.startTime;
          const startTime = rawStartTime instanceof Timestamp ? rawStartTime.toDate() : null;
          
          if (startTime) {
            const bookingDate = startTime.toISOString().split('T')[0]; // YYYY-MM-DD
            const month = bookingDate.substring(0, 7); // YYYY-MM
            
            // Собираем статистику
            bookingDateStats.allDates.push(bookingDate);
            bookingDateStats.byMonth[month] = (bookingDateStats.byMonth[month] || 0) + 1;
            
            // При первом запросе документы уже отфильтрованы,
            // При втором проверяем попадание в диапазон
            const isInRange = startTime >= start && startTime <= end;
            
            if (isInRange) {
              // Проверяем наличие всех необходимых полей Booking
              if (data.price !== undefined && data.status && 
                  data.locationId && data.uid && data.officeId) {
                
                // Создаем корректный объект Booking
                const booking: Booking = {
                  id: doc.id,
                  startTime: startTime,
                  endTime: convertTimestampToDate(data.endTime),
                  createdAt: convertTimestampToDate(data.createdAt),
                  price: data.price,
                  status: data.status,
                  locationId: data.locationId,
                  uid: data.uid,
                  officeId: data.officeId,
                  customer_first_name: data.customer_first_name || '',
                  customer_last_name: data.customer_last_name || '',
                  payment: data.payment
                };
                
                allBookings.push(booking);
              }
            }
          } else {
            console.warn(`Бронирование с невалидной датой: ${doc.id}`, rawStartTime);
            bookingDateStats.invalid++;
          }
        });
        
        // Применяем дополнительные фильтры на стороне клиента
        let filteredBookings = allBookings;
        
        // Фильтр по офису
        if (filters.officeId) {
          filteredBookings = filteredBookings.filter(booking => 
            booking.officeId === filters.officeId
          );
        }
        
        // Фильтр по клиенту
        if (filters.clientId) {
          filteredBookings = filteredBookings.filter(booking => 
            booking.uid === filters.clientId
          );
        }
        
        console.log(`После применения всех фильтров осталось ${filteredBookings.length} бронирований`);
        
        // Сортируем результаты по убыванию даты начала бронирования
        filteredBookings.sort((a, b) => b.startTime.getTime() - a.startTime.getTime());
        
        // Получаем названия офисов для всех бронирований
        const officeNames = await Promise.all(
          filteredBookings.map(async (booking) => {
            if (booking.officeId) {
              try {
                const officeDoc = await getDoc(doc(db, 'offices', booking.officeId));
                if (officeDoc.exists()) {
                  const officeData = officeDoc.data();
                  return { id: booking.officeId, bookingId: booking.id, name: officeData.name || 'Офис' };
                }
              } catch (error) {
                console.error(`Error fetching office name for booking ${booking.id}:`, error);
              }
            }
            return { id: booking.officeId || '', bookingId: booking.id, name: 'Офис' };
          })
        );
        
        // Добавляем названия офисов к бронированиям
        const bookingsWithOfficeNames = filteredBookings.map(booking => {
          const officeName = officeNames.find(office => office.bookingId === booking.id)?.name;
          return { ...booking, officeName };
        });
        
        return bookingsWithOfficeNames;
      } catch (error: any) {
        console.error('Error fetching bookings:', error);
        if (error.code === 'permission-denied') {
          console.error('Permission denied. Please check if you have access to this location');
        }
        throw error;
      }
    },
    enabled: !!locationId,
    retry: false,
  });
};

// Хук для получения метрик с дополнительными фильтрами
export const useFinanceMetrics = (
  locationId: string | undefined, 
  filters: FinanceFilters
) => {
  const { data: bookings = [], isLoading, error } = useFinanceBookings(locationId, filters);

  // Отфильтруем бесплатные бронирования для метрик (те, у которых метод оплаты FREE)
  const paidBookings = bookings.filter(booking => 
    booking.payment?.method !== PaymentMethod.FREE
  );

  const metrics = {
    totalRevenue: 0,
    currentRevenue: 0,
    upcomingRevenue: 0,
    totalBookings: paidBookings.length, // Считаем только платные бронирования
    cancelledBookings: 0,
    cancellationRate: 0,
  };

  if (!isLoading && !error) {
    const now = new Date();
    
    paidBookings.forEach(booking => {
      const isConfirmedOrPaid = booking.status === 'CONFIRMED' || booking.status === 'PAID' || 
                               (booking.payment && (booking.payment.status === 'CONFIRMED' || booking.payment.status === 'PAID'));
      const isCancelled = booking.status === 'CANCELLED';
      
      // Подсчет отмен (только платные)
      if (isCancelled) {
        metrics.cancelledBookings++;
      }
      
      // Подсчет выручки (только подтвержденные/оплаченные платные бронирования)
      if (isConfirmedOrPaid && !isCancelled) {
        const bookingPrice = booking.price;
        
        // Если дата бронирования в будущем - это будущая выручка
        if (booking.startTime > now) {
          metrics.upcomingRevenue += bookingPrice;
        } 
        // Иначе - текущая выручка (бронирования в выбранном периоде и прошлом)
        else {
          metrics.currentRevenue += bookingPrice;
        }
        
        // Общая выручка - сумма текущей и будущей
        metrics.totalRevenue = metrics.currentRevenue + metrics.upcomingRevenue;
      }
    });

    metrics.cancellationRate = metrics.totalBookings > 0 
      ? metrics.cancelledBookings / metrics.totalBookings 
      : 0;
  }

  return {
    ...metrics,
    paidBookings, // Возвращаем отфильтрованный список платных бронирований
    isLoading,
    error,
  };
};

// Хук для получения списка уникальных офисов из бронирований
export const useOfficeOptions = (locationId: string | undefined, period: Period) => {
  // Используем только фильтр по периоду, без дополнительных фильтров
  const { data: bookings = [], isLoading } = useQuery<Booking[]>({
    queryKey: ['officeOptions', locationId, period],
    queryFn: async () => {
      if (!locationId) return [];
      
      try {
        // Вместо поиска в бронированиях, получаем все офисы напрямую
        const officesRef = collection(db, 'offices');
        const officesQuery = query(officesRef, where('locationId', '==', locationId));
        const officesSnapshot = await getDocs(officesQuery);
        
        console.log(`Получено ${officesSnapshot.size} офисов для локации ${locationId}`);
        
        // Преобразуем результаты
        const officesArray: Booking[] = [];
        
        // Преобразуем результаты в формат Booking для совместимости
        for (const docSnapshot of officesSnapshot.docs) {
          const data = docSnapshot.data();
          
          // Создаем минимальный объект Booking с обязательными полями
          const officeBooking: Booking = {
            id: docSnapshot.id,
            officeId: docSnapshot.id,
            officeName: data.name || 'Офис',
            startTime: new Date(),
            endTime: new Date(),
            createdAt: new Date(),
            price: 0,
            status: '',
            locationId: locationId,
            uid: '',
            customer_first_name: '',
            customer_last_name: ''
          };
          
          officesArray.push(officeBooking);
        }
        
        return officesArray;
      } catch (error) {
        console.error('Error fetching office options:', error);
        return [];
      }
    },
    enabled: !!locationId,
    staleTime: 5 * 60 * 1000, // Кешируем на 5 минут
  });
  
  const officeOptions: Office[] = [];
  const officeIds = new Set<string>();
  
  if (!isLoading && bookings.length > 0) {
    bookings.forEach(booking => {
      if (booking.officeId && booking.officeName && !officeIds.has(booking.officeId)) {
        officeIds.add(booking.officeId);
        officeOptions.push({
          id: booking.officeId,
          name: booking.officeName
        });
      }
    });
  }
  
  console.log(`Получено ${officeOptions.length} уникальных офисов для фильтра`);
  
  // Сортируем офисы по имени
  return officeOptions.sort((a, b) => a.name.localeCompare(b.name));
};

// Хук для получения списка уникальных клиентов из бронирований
export const useClientOptions = (locationId: string | undefined, period: Period) => {
  // Используем только фильтр по периоду, без дополнительных фильтров
  const { data: bookings = [], isLoading } = useQuery<Booking[]>({
    queryKey: ['clientOptions', locationId, period],
    queryFn: async () => {
      if (!locationId) return [];
      
      try {
        const { start, end } = getDateRangeForPeriod(period);
        const bookingsRef = collection(db, 'bookings');
        
        // Преобразуем JavaScript Date в Firestore Timestamp
        const startTimestamp = Timestamp.fromDate(start);
        const endTimestamp = Timestamp.fromDate(end);
        
        // Запрос для получения бронирований по локации и периоду
        const baseQuery = query(
          bookingsRef,
          where('locationId', '==', locationId),
          where('startTime', '>=', startTimestamp),
          where('startTime', '<=', endTimestamp)
        );
        
        const bookingsSnapshot = await getDocs(baseQuery);
        
        // Преобразуем результаты
        const bookingsArray: Booking[] = [];
        
        for (const docSnapshot of bookingsSnapshot.docs) {
          const data = docSnapshot.data();
          
          // Проверяем наличие всех необходимых полей Booking
          if (data.startTime && data.endTime && data.price !== undefined && 
              data.status && data.locationId && data.uid && data.officeId) {
            
            const booking: Booking = {
              id: docSnapshot.id,
              startTime: convertTimestampToDate(data.startTime),
              endTime: convertTimestampToDate(data.endTime),
              createdAt: convertTimestampToDate(data.createdAt),
              price: data.price,
              status: data.status,
              locationId: data.locationId,
              uid: data.uid,
              officeId: data.officeId,
              customer_first_name: data.customer_first_name || '',
              customer_last_name: data.customer_last_name || '',
              payment: data.payment
            };
            
            bookingsArray.push(booking);
          }
        }
        
        return bookingsArray;
      } catch (error) {
        console.error('Error fetching client options:', error);
        return [];
      }
    },
    enabled: !!locationId,
    staleTime: 5 * 60 * 1000, // Кешируем на 5 минут
  });
  
  const clientOptions: Client[] = [];
  const clientIds = new Set<string>();
  
  if (!isLoading && bookings.length > 0) {
    bookings.forEach(booking => {
      if (booking.uid && !clientIds.has(booking.uid)) {
        clientIds.add(booking.uid);
        
        // Формируем полное имя
        const firstName = booking.customer_first_name || '';
        const lastName = booking.customer_last_name || '';
        const fullName = `${firstName} ${lastName}`.trim() || 'Неизвестный клиент';
        
        clientOptions.push({
          id: booking.uid,
          fullName
        });
      }
    });
  }
  
  // Сортируем клиентов по имени
  return clientOptions.sort((a, b) => a.fullName.localeCompare(b.fullName));
}; 