import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Switch, FormControlLabel, Paper, Typography, InputAdornment, Select, MenuItem, FormHelperText, Accordion, AccordionSummary, AccordionDetails, Fab, Snackbar, CircularProgress, RadioGroup, Radio, FormControl, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Office, OfficeService } from 'shared-lib';
import { useParams } from 'react-router-dom';
import { useUnsavedChangesWarning } from '../hooks/useUnsavedChangesWarning';
import { useAuth } from 'shared-lib';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { storage } from 'shared-lib';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const OfficeEditForm: React.FC = () => {
  const { officeId } = useParams<{ officeId: string }>();
  const [office, setOffice] = useState<Office | null>(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [durationWithoutPause, setDurationWithoutPause] = useState(0);
  const [isPause, setIsPause] = useState(false);
  const [pause, setPause] = useState(0);
  const [basePrice, setBasePrice] = useState(0);
  const [multipleSlotPrice, setMultipleSlotPrice] = useState(0);
  const [step, setStep] = useState(0);
  const [minMinutesBeforeBooking, setMinMinutesBeforeBooking] = useState(60);
  const [maxFutureMinutes, setMaxFutureMinutes] = useState(7 * 24 * 60);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [calendars, setCalendars] = useState<{ id: string; summary: string }[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<string | null>(null);
  const [loadingCalendars, setLoadingCalendars] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [selectedCalendarName, setSelectedCalendarName] = useState<string | null>(null);
  const [isOwner, setIsOwner] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [allowMultipleSlots, setAllowMultipleSlots] = useState(false);
  const [minimumSlots, setMinimumSlots] = useState(1);
  const [dayPrice, setDayPrice] = useState(0);

  const { user } = useAuth();
  const functions = getFunctions(undefined, 'europe-west3');
  const officeService = OfficeService.getInstance();

  useEffect(() => {
    const fetchOffice = async () => {
      if (!officeId || !user) return;

      try {
        setLoading(true);
        const fetchedOffice = await officeService.getOfficeById(officeId);
        if (fetchedOffice) {
          setOffice(fetchedOffice);
          setName(fetchedOffice.name);
          setDescription(fetchedOffice.description || '');
          setDurationWithoutPause(fetchedOffice.duration.duration_without_pause || 0);
          setIsPause(fetchedOffice.duration.is_pause || false);
          setPause(fetchedOffice.duration.pause || 0);
          setBasePrice((fetchedOffice.price.base_price || 0) / 100);
          setMultipleSlotPrice((fetchedOffice.price.multiple_slot_price || 0) / 100);
          setStep(fetchedOffice.bookingSettings.step || 0);
          setMinMinutesBeforeBooking(fetchedOffice.bookingSettings.minFutureMinutes || 60);
          setMaxFutureMinutes(fetchedOffice.bookingSettings.maxFutureMinutes || 7 * 24 * 60);
          setSelectedCalendar(fetchedOffice.googleCalendar?.id || null);
          setSelectedCalendarName(fetchedOffice.googleCalendar?.name || null);
          setIsOwner(fetchedOffice.ownerUid === user.uid);
          setImages(fetchedOffice.images || []);
          setAllowMultipleSlots(fetchedOffice.bookingSettings.allow_multiple_slots || false);
          setMinimumSlots(fetchedOffice.bookingSettings.minimum_slots || 1);
          setDayPrice((fetchedOffice.price.day_price || 0) / 100);
        }
      } catch (error) {
        console.error('Error fetching office:', error);
        setError('Failed to load office data');
      } finally {
        setLoading(false);
      }
    };

    fetchOffice();
  }, [officeId, officeService, user]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<any>>) => (value: any) => {
    setter(value);
    setIsChanged(true);
  };

  const handleSave = async () => {
    if (!office) return;

    try {
      setSaving(true);
      const updatedOffice = {
        ...office,
        name,
        description,
        duration: {
          duration: 0,
          duration_without_pause: durationWithoutPause,
          is_pause: isPause,
          pause,
        },
        price: {
          base_price: Math.round(basePrice * 100),
          multiple_slot_price: Math.round(multipleSlotPrice * 100),
          day_price: Math.round(dayPrice * 100),
        },
        bookingSettings: {
          step: step,
          minFutureMinutes: minMinutesBeforeBooking,
          maxFutureMinutes: maxFutureMinutes,
          allow_multiple_slots: allowMultipleSlots,
          minimum_slots: minimumSlots,
        },
        googleCalendar: selectedCalendar ? {
          id: selectedCalendar,
          name: calendars.find(cal => cal.id === selectedCalendar)?.summary || ''
        } : null,
        images: images
      } as Office;
      console.log('Saving office:', updatedOffice);
      const savedOffice = await officeService.updateOffice(updatedOffice);
      console.log('Saved office:', savedOffice);
      setError(null);
      setIsChanged(false);
      setSuccessMessage('Настройки помещения успешно сохранены');
      setOffice(savedOffice);
    } catch (error) {
      console.error('Error saving office:', error);
      setError('Не удалось сохранить настройки офиса');
    } finally {
      setSaving(false);
    }
  };

  const handleLoadCalendars = async () => {
    if (!office) return;

    try {
      setLoadingCalendars(true);
      const listCalendars = httpsCallable<{ officeId: string }, { calendars?: { id: string; summary: string }[], error?: string }>(functions, 'listCalendars');
      const result = await listCalendars({ officeId: office.id });

      if (result.data.error) {
        setError(result.data.error);
      } else if (result.data.calendars && Array.isArray(result.data.calendars)) {
        setCalendars(result.data.calendars);
      } else {
        throw new Error('Unexpected response format from listCalendars');
      }
    } catch (error) {
      console.error('Error loading calendars:', error);
      setError('Не удалось загрузить список календарей');
    } finally {
      setLoadingCalendars(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleOpenCalendar = (calendarId: string) => {
    const calendarUrl = `https://calendar.google.com/calendar/embed?src=${encodeURIComponent(calendarId)}`;
    window.open(calendarUrl, '_blank');
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !office || !user) return;
    
    const file = event.target.files[0];
    setUploading(true);

    try {
      const storageRef = ref(storage, `offices/${office.id}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      
      const newImages = [...images, downloadURL];
      setImages(newImages);
      
      // Сохраняем обновленный офис сразу после загрузки изображения
      const updatedOffice = { ...office, images: newImages };
      await officeService.updateOffice(updatedOffice);
      
      setIsChanged(false);
      setSuccessMessage('Изображение успешно загружено и сохранено');
    } catch (error) {
      console.error('Error uploading image:', error);
      setError(error instanceof Error ? error.message : 'Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = async (imageUrl: string) => {
    if (!office || !user) return;

    try {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      
      const newImages = images.filter(img => img !== imageUrl);
      setImages(newImages);
      
      // Сохраняем обновленный офис сразу после удаления изображения
      const updatedOffice = { ...office, images: newImages };
      await officeService.updateOffice(updatedOffice);
      
      setIsChanged(false);
      setSuccessMessage('Изображение успешно удалено');
    } catch (error) {
      console.error('Error deleting image:', error);
      setError(error instanceof Error ? error.message : 'Failed to delete image');
    }
  };

  const handleSetMainImage = async (imageUrl: string) => {
    if (!office || !user) return;

    try {
      const newImages = [imageUrl, ...images.filter(img => img !== imageUrl)];
      setImages(newImages);

      // Сохраняем обновленный офис с новым порядком изображений
      const updatedOffice = { ...office, images: newImages };
      await officeService.updateOffice(updatedOffice);

      setIsChanged(false);
      setSuccessMessage('Основное изображение успешно обновлено');
    } catch (error) {
      console.error('Error setting main image:', error);
      setError(error instanceof Error ? error.message : 'Failed to set main image');
    }
  };

  useUnsavedChangesWarning(
    isChanged,
    'У вас есть несохраненные изменения. Вы уверены, что хотите покнуть эту страницу?'
  );

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!office) {
    return <Typography>Office not found</Typography>;
  }

  return (
    <Box sx={{ mt: 2, mb: 10 }}>
      {/* Основная информация */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Основная информация</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            label="Название офиса"
            value={name}
            onChange={(e) => handleInputChange(setName)(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Описание"
            value={description}
            onChange={(e) => handleInputChange(setDescription)(e.target.value)}
            margin="normal"
            multiline
            rows={4}
          />
        </AccordionDetails>
      </Accordion>

      {/* Photo Management */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Фотографии офиса</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {images.map((image, index) => (
              <Box key={index} sx={{ position: 'relative' }}>
                <img src={image} alt={`Office ${index + 1}`} style={{ width: 200, height: 150, objectFit: 'cover' }} />
                <IconButton
                  sx={{ position: 'absolute', top: 5, right: 5, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                  onClick={() => handleDeleteImage(image)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  sx={{ position: 'absolute', top: 5, left: 5, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
                  onClick={() => handleSetMainImage(image)}
                >
                  {index === 0 ? <StarIcon color="primary" /> : <StarBorderIcon />}
                </IconButton>
              </Box>
            ))}
          </Box>
          <Button
            variant="outlined"
            component="label"
            startIcon={<AddPhotoAlternateIcon />}
            sx={{ mt: 2 }}
            disabled={uploading}
          >
            {uploading ? 'Загрузка...' : 'Добавить фото'}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Настройка продолжительности */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Настройка продолжительности</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            label="Длительность (мин)"
            type="number"
            value={durationWithoutPause}
            onChange={(e) => handleInputChange(setDurationWithoutPause)(Number(e.target.value))}
            margin="normal"
            required
          />
          <FormControlLabel
            control={
              <Switch
                checked={isPause}
                onChange={(e) => handleInputChange(setIsPause)(e.target.checked)}
              />
            }
            label="Пауза после услуги"
          />
          {isPause && (
            <TextField
              fullWidth
              label="Продолжительность паузы (мин)"
              type="number"
              value={pause}
              onChange={(e) => handleInputChange(setPause)(Number(e.target.value))}
              margin="normal"
              required
            />
          )}
        </AccordionDetails>
      </Accordion>

      {/* Настройки цены */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Настройки цены</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            label="Базовая цена за слот"
            type="number"
            value={basePrice}
            onChange={(e) => handleInputChange(setBasePrice)(Number(e.target.value))}
            margin="normal"
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">₽</InputAdornment>,
            }}
            helperText="Цена за один слот при обычном бронировании"
          />
          <Box sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={allowMultipleSlots}
                  onChange={(e) => handleInputChange(setAllowMultipleSlots)(e.target.checked)}
                />
              }
              label="Разрешить бронирование нескольких слотов"
            />
          </Box>
          {allowMultipleSlots && (
            <>
              <TextField
                fullWidth
                label="Минимальное количество слотов"
                type="number"
                value={minimumSlots}
                onChange={(e) => handleInputChange(setMinimumSlots)(Number(e.target.value))}
                margin="normal"
                InputProps={{ inputProps: { min: 1 } }}
                helperText="При бронировании этого количества слотов или больше будет применяться цена множественного бронирования"
              />
              <TextField
                fullWidth
                label="Цена за один слот при множественном бронировании"
                type="number"
                value={multipleSlotPrice}
                onChange={(e) => handleInputChange(setMultipleSlotPrice)(Number(e.target.value))}
                margin="normal"
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                }}
                helperText="Цена за каждый слот при бронировании указанного выше минимального количества слотов или больше"
              />
              <TextField
                fullWidth
                label="Цена за весь день"
                type="number"
                value={dayPrice}
                onChange={(e) => handleInputChange(setDayPrice)(Number(e.target.value))}
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                }}
                helperText="Оставьте 0, если бронирование на весь день недоступно"
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Настройки бронирования */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Настройки бронирования</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Шаг нарезки</Typography>
            <Select
              fullWidth
              value={step}
              onChange={(e) => handleInputChange(setStep)(Number(e.target.value))}
            >
              <MenuItem value={0}>По длительности услуги</MenuItem>
              <MenuItem value={10}>10 минут</MenuItem>
              <MenuItem value={15}>15 минут</MenuItem>
              <MenuItem value={20}>20 минут</MenuItem>
              <MenuItem value={30}>30 минут</MenuItem>
              <MenuItem value={45}>45 минут</MenuItem>
              <MenuItem value={60}>1 час</MenuItem>
              <MenuItem value={90}>1 час 30 мин</MenuItem>
              <MenuItem value={120}>2 часа</MenuItem>
            </Select>
            <FormHelperText>
              Интервалы времени (слоты), по которым возможна запись клиентов.
              Указанный шаг в 15 минут позволит клиентам бронировать время,
              например, в 10:00, 10:15, 10:30 и так далее.
            </FormHelperText>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Минимальное время до начала (мин)</Typography>
            <Select
              fullWidth
              value={minMinutesBeforeBooking}
              onChange={(e) => handleInputChange(setMinMinutesBeforeBooking)(Number(e.target.value))}
            >
              <MenuItem value={0}>Без ограничений (можно бронировать сразу)</MenuItem>
              <MenuItem value={30}>Не ранее чем за 30 минут</MenuItem>
              <MenuItem value={60}>Не ранее чем за 1 час</MenuItem>
              <MenuItem value={120}>Не ранее чем за 2 часа</MenuItem>
              <MenuItem value={180}>Не ранее чем за 3 часа</MenuItem>
              <MenuItem value={240}>Не ранее чем за 4 часа</MenuItem>
              <MenuItem value={24 * 60}>Не ранее чем за 1 день</MenuItem>
              <MenuItem value={2 * 24 * 60}>Не ранее чем за 2 дня</MenuItem>
              <MenuItem value={3 * 24 * 60}>Не ранее чем за 3 дня</MenuItem>
              <MenuItem value={7 * 24 * 60}>Не ранее чем за неделю</MenuItem>
            </Select>
            <FormHelperText>
              Время после текущего момента, после которого будут доступны слоты
              бронирования.
            </FormHelperText>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Максимальный период бронирования (мин)</Typography>
            <Select
              fullWidth
              value={maxFutureMinutes}
              onChange={(e) => handleInputChange(setMaxFutureMinutes)(Number(e.target.value))}
            >
              <MenuItem value={7 * 24 * 60}>на 1 неделю вперед</MenuItem>
              <MenuItem value={14 * 24 * 60}>на 2 недели вперед</MenuItem>
              <MenuItem value={30 * 24 * 60}>на 1 месяц вперед</MenuItem>
              <MenuItem value={60 * 24 * 60}>на 2 месяца вперед</MenuItem>
            </Select>
            <FormHelperText>
              Время, в течение которого можно записаться на прием в будущем. Если
              указать на 7 дней вперёд, то онлайн-запись на 8 день будет недоступна.
            </FormHelperText>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Google Calendar */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Календарь Google</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Календарь Google используется для отражения в нем бронирований.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {selectedCalendar ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body1">
                    Текущий калндарь: <strong>{selectedCalendarName || 'Не выбран'}</strong>
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleOpenCalendar(selectedCalendar)}
                    title="Открыть календарь в новой вкладке"
                  >
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </Box>
                {isOwner ? (
                  <Button
                    variant="outlined"
                    onClick={handleLoadCalendars}
                    disabled={loadingCalendars}
                    startIcon={loadingCalendars ? <CircularProgress size={20} /> : null}
                  >
                    {loadingCalendars ? 'Загрузка...' : 'Изменить календарь'}
                  </Button>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Только владелец офиса может изменить привязанный календарь.
                  </Typography>
                )}
              </>
            ) : (
              <>
                {isOwner ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLoadCalendars}
                    disabled={loadingCalendars}
                    startIcon={loadingCalendars ? <CircularProgress size={20} /> : null}
                  >
                    {loadingCalendars ? 'Загрузка...' : 'Привязать календарь'}
                  </Button>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Календарь не привязан. Только владелец офиса может привязать календарь.
                  </Typography>
                )}
              </>
            )}
            {isOwner && !!calendars.length && (
              <FormControl component="fieldset" sx={{ mt: 2 }}>
                <RadioGroup
                  value={selectedCalendar || ''}
                  onChange={(e) => {
                    handleInputChange(setSelectedCalendar)(e.target.value);
                    const selectedCal = calendars.find(cal => cal.id === e.target.value);
                    setSelectedCalendarName(selectedCal ? selectedCal.summary : null);
                  }}
                >
                  <FormControlLabel value="" control={<Radio />} label="Не выбран" />
                  {calendars.map((calendar) => (
                    <FormControlLabel
                      key={calendar.id}
                      value={calendar.id}
                      control={<Radio />}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {calendar.summary}
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleOpenCalendar(calendar.id);
                            }}
                            title="Открыть календарь в новой вкладке"
                          >
                            <OpenInNewIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Fab
        color="primary"
        size="small"
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 80, right: 16 }}
      >
        <KeyboardArrowUpIcon />
      </Fab>

      <Paper
        elevation={3}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: 'background.paper',
          zIndex: 1100,
        }}
      >
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={saving || !isChanged}
          startIcon={<SaveIcon />}
        >
          {saving ? 'Сохранение...' : 'Сохранить изменения'}
        </Button>
      </Paper>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
      />
    </Box>
  );
};

export default OfficeEditForm;
