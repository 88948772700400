import React from 'react';
import { 
  Drawer,
  Box, 
  List, 
  ListItem, 
  ListItemButton,
  ListItemText, 
  ListItemIcon,
  IconButton,
  Divider, 
  Button,
  Typography
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useLocation } from '../../contexts/LocationContext';
import { useNavigate } from 'react-router-dom';
import { Location } from '../../hooks/useLocationsQueries';

interface LocationDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const LocationDrawer: React.FC<LocationDrawerProps> = ({
  open,
  onClose,
  onOpen
}) => {
  const { currentLocation, availableLocations, setCurrentLocation } = useLocation();
  const navigate = useNavigate();

  const handleLocationSelect = (location: Location) => {
    setCurrentLocation(location);
    onClose();
  };

  const handleEdit = (event: React.MouseEvent, locationId: string) => {
    event.stopPropagation(); // Предотвращаем выбор локации при клике на кнопку редактирования
    navigate(`/locations/${locationId}/edit`);
    onClose();
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { 
          maxHeight: '80vh',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }
      }}
    >
      <Box sx={{ 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Выберите локацию
        </Typography>
        <List sx={{ flexGrow: 1, overflow: 'auto' }}>
          {availableLocations.map((location) => (
            <ListItem 
              key={location.id} 
              disablePadding
              secondaryAction={
                <IconButton 
                  edge="end" 
                  onClick={(e) => handleEdit(e, location.id)}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <ListItemButton
                selected={location.id === currentLocation?.id}
                onClick={() => handleLocationSelect(location)}
              >
                <ListItemIcon>
                  <LocationOnIcon color={location.id === currentLocation?.id ? "primary" : undefined} />
                </ListItemIcon>
                <ListItemText 
                  primary={location.name}
                  secondary={location.address}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ my: 2 }} />
        <Button
          fullWidth
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            navigate('/locations/new');
            onClose();
          }}
        >
          Добавить локацию
        </Button>
      </Box>
    </Drawer>
  );
};

export default LocationDrawer; 