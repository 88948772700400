import React, { useState, useCallback } from 'react';
import { TextField, Typography } from '@mui/material';

interface InlineEditProps {
  value: string;
  onSave: (newValue: string) => Promise<void>;
}

export const InlineEdit: React.FC<InlineEditProps> = React.memo(({ value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [error, setError] = useState<string | null>(null);

  const handleStartEdit = useCallback(() => {
    setIsEditing(true);
    setEditValue(value);
    setError(null);
  }, [value]);

  const handleSave = useCallback(async () => {
    try {
      await onSave(editValue);
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Ошибка при сохранении');
    }
  }, [editValue, onSave]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditValue(value);
      setError(null);
    }
  }, [handleSave, value]);

  if (isEditing) {
    return (
      <TextField
        size="small"
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
        onBlur={handleSave}
        onKeyDown={handleKeyDown}
        error={Boolean(error)}
        helperText={error}
        autoFocus
        sx={{ minWidth: 200 }}
      />
    );
  }

  return (
    <Typography
      onClick={handleStartEdit}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline'
        }
      }}
    >
      {value}
    </Typography>
  );
}); 