import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { collection, getDocs } from 'firebase/firestore';
import { db } from 'shared-lib';
import { useAuth } from 'shared-lib';
import { Location } from '../hooks/useLocationsQueries';
import { LocationService } from 'shared-lib';

const STORAGE_KEY = 'selectedLocationId';

interface LocationContextType {
  currentLocation: Location | null;
  availableLocations: Location[];
  setCurrentLocation: (location: Location) => void;
  isLoading: boolean;
  error: Error | null;
}

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const locationService = LocationService.getInstance();

  const { data: locations = [], isLoading, error } = useQuery<Location[]>({
    queryKey: ['available-locations', user?.uid],
    queryFn: async () => {
      if (!user) return [];
      const querySnapshot = await getDocs(collection(db, 'locations'));
      const allLocations = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Location));

      // Фильтруем локации, где пользователь является владельцем или админом
      const filteredLocations = await Promise.all(
        allLocations.map(async (location) => {
          const isOwner = location.ownerUid === user.uid;
          const isAdmin = await locationService.isLocationAdmin(user.uid, location.id);
          return isOwner || isAdmin ? location : null;
        })
      );

      return filteredLocations.filter((location): location is Location => location !== null);
    },
    enabled: !!user
  });

  // Загружаем сохраненную локацию при старте
  useEffect(() => {
    if (!currentLocation && locations.length > 0) {
      const savedLocationId = localStorage.getItem(STORAGE_KEY);
      if (savedLocationId) {
        const savedLocation = locations.find(loc => loc.id === savedLocationId);
        if (savedLocation) {
          setCurrentLocation(savedLocation);
        }
      }
      // Теперь если нет сохраненной локации, currentLocation останется null
    }
  }, [locations, currentLocation]);

  const handleSetCurrentLocation = (location: Location) => {
    setCurrentLocation(location);
    localStorage.setItem(STORAGE_KEY, location.id);
  };

  return (
    <LocationContext.Provider 
      value={{ 
        currentLocation, 
        availableLocations: locations,
        setCurrentLocation: handleSetCurrentLocation,
        isLoading,
        error: error as Error | null
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
}; 