import React from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { AppBar } from 'shared-lib';
import BottomAppBar from './BottomAppBar';
import { useAuth } from 'shared-lib';
import { LocationSelector } from './LocationSelector';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Business from '@mui/icons-material/Business';
import LocationOn from '@mui/icons-material/LocationOn';
import People from '@mui/icons-material/People';
import Person from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';

const Layout: React.FC = () => {
  const location = useLocation();
  const { user, loading } = useAuth();

  // Wait for auth state to load before redirecting
  if (loading) {
    return null; // или показать спиннер загрузки
  }

  // Redirect to login if not authenticated
  if (!user && location.pathname !== '/login') {
    return <Navigate to="/login" replace />;
  }

  const getPageInfo = () => {
    const pathname = location.pathname;
    switch (pathname) {
      case '/':
        return { title: 'Календарь', showBackButton: false };
      case '/finance':
        return { title: 'Финансы', showBackButton: false };
      case '/residents':
        return { title: 'Резиденты', showBackButton: false };
      case '/settings':
        return { title: 'Настройки', showBackButton: false };
      case '/offices':
        return { title: 'Мои помещения', showBackButton: true, backTo: '/settings' };
      default:
        if (pathname.startsWith('/offices/') && pathname.endsWith('/schedule')) {
          return { title: 'Расписание офиса', showBackButton: true, backTo: '/offices' };
        }
        if (pathname.startsWith('/offices/') && pathname.endsWith('/edit')) {
          return { title: 'Редактирование помещения', showBackButton: true, backTo: '/offices' };
        }
        return { title: 'Админ-панель', showBackButton: false };
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const menuItems = [
    {
      text: 'Календарь',
      icon: <CalendarMonth />,
      path: '/',
    },
    {
      text: 'Офисы',
      icon: <Business />,
      path: '/offices',
    },
    {
      text: 'Локации',
      icon: <LocationOn />,
      path: '/locations',
    },
    {
      text: 'Резиденты',
      icon: <People />,
      path: '/residents',
    },
    {
      text: 'Пользователи',
      icon: <Person />,
      path: '/users',
    },
    {
      text: 'Настройки',
      icon: <Settings />,
      path: '/settings',
    },
  ];

  return (
    <>
      <AppBar 
        getPageInfo={getPageInfo}
        rightContent={<LocationSelector />}
      />
      <Box sx={{ pb: 7 }}>
        <Outlet />
      </Box>
      {user && <BottomAppBar />}
    </>
  );
};

export default Layout;
