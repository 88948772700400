import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLocation } from '../contexts/LocationContext';
import LocationDrawer from './locations/LocationDrawer';

export const LocationSelector = () => {
  const [open, setOpen] = useState(false);
  const { currentLocation } = useLocation();

  return (
    <Box>
      <Button
        color="inherit"
        onClick={() => setOpen(true)}
        startIcon={<LocationOnIcon />}
      >
        {currentLocation?.name || 'Выберите локацию'}
      </Button>
      <LocationDrawer 
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    </Box>
  );
}; 