import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Container,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  IconButton,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc, getFirestore, query, where } from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLocation } from '../../contexts/LocationContext';
import { Location } from '../../hooks/useLocationsQueries';

// Расширенный интерфейс для отображения локаций
interface LocationExtended extends Location {
  description?: string;
  mainImage?: string;
  images?: string[];
  isActive: boolean;
}

const LocationsPage: React.FC = () => {
  const [locations, setLocations] = useState<LocationExtended[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationExtended | null>(null);
  const navigate = useNavigate();
  const db = getFirestore();
  const { setCurrentLocation } = useLocation();

  // Оборачиваем функцию loadLocations в useCallback и перемещаем выше useEffect
  const loadLocations = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const locationsQuery = query(collection(db, 'locations'), where('isActive', '==', true));
      const locationsSnapshot = await getDocs(locationsQuery);
      const locationsData: LocationExtended[] = locationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      } as LocationExtended));

      setLocations(locationsData);
    } catch (e) {
      console.error('Error loading locations:', e);
      setError('Ошибка при загрузке локаций');
    } finally {
      setLoading(false);
    }
  }, [db, setError, setLoading]);

  // Загрузка локаций
  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  // Функция удаления локации
  const handleDeleteLocation = async () => {
    if (!selectedLocation) return;

    try {
      // Проверка на наличие офисов в локации
      const officesQuery = query(
        collection(db, 'offices'), 
        where('locationId', '==', selectedLocation.id),
        where('is_active', '==', true)
      );
      const officesSnapshot = await getDocs(officesQuery);
      
      if (!officesSnapshot.empty) {
        setError('Невозможно удалить локацию, так как в ней есть активные офисы');
        setOpenDeleteDialog(false);
        return;
      }

      // Проверка на наличие резидентов
      const residentsQuery = query(
        collection(db, 'locationUsers'),
        where('locationId', '==', selectedLocation.id)
      );
      const residentsSnapshot = await getDocs(residentsQuery);

      if (!residentsSnapshot.empty) {
        setError('Невозможно удалить локацию, так как у неё есть резиденты');
        setOpenDeleteDialog(false);
        return;
      }

      // Мягкое удаление - установка isActive в false
      await updateDoc(doc(db, 'locations', selectedLocation.id), {
        isActive: false
      });

      setSuccess('Локация успешно удалена');
      await loadLocations();
    } catch (e) {
      console.error('Error deleting location:', e);
      setError('Ошибка при удалении локации');
    } finally {
      setOpenDeleteDialog(false);
      setSelectedLocation(null);
    }
  };

  // Обработчик выбора локации
  const handleSelectLocation = (location: LocationExtended) => {
    // Не передаем adminUids
    setCurrentLocation({
      id: location.id,
      name: location.name,
      address: location.address,
      timezone: location.timezone,
      ownerUid: location.ownerUid || ''
    });
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1">
          Локации
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={() => navigate('/locations/new')}
        >
          Добавить локацию
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {locations.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center">Локации не найдены</Typography>
            </Grid>
          ) : (
            locations.map((location) => (
              <Grid item xs={12} sm={6} md={4} key={location.id}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    '&:hover': {
                      boxShadow: 6
                    },
                    cursor: 'pointer'
                  }}
                  onClick={() => handleSelectLocation(location)}
                >
                  <Box 
                    sx={{
                      height: 200,
                      backgroundImage: location.mainImage ? `url(${location.mainImage})` : 'none',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: location.mainImage ? 'transparent' : 'grey.300'
                    }}
                  >
                    {!location.mainImage && <LocationOnIcon sx={{ fontSize: 60, color: 'grey.500' }} />}
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {location.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {location.address}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2">
                        Часовой пояс: {location.timezone}
                      </Typography>
                    </Box>
                    {location.description && (
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        {location.description.length > 100 
                          ? `${location.description.substring(0, 100)}...` 
                          : location.description}
                      </Typography>
                    )}
                  </CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/locations/${location.id}/edit`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedLocation(location);
                        setOpenDeleteDialog(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}

      {/* Диалог подтверждения удаления */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Удалить локацию</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите удалить локацию "{selectedLocation?.name}"? Это действие нельзя будет отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Отмена</Button>
          <Button onClick={handleDeleteLocation} color="error" variant="contained">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LocationsPage; 