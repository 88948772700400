import React, { useState, useEffect } from 'react';
import { List, Box, useMediaQuery, useTheme, Typography, Container } from '@mui/material';
import { useAuth } from 'shared-lib';
import { OfficeService } from 'shared-lib';
import { Office } from 'shared-lib';
import { useNavigate } from 'react-router-dom';
import OfficeItem from './OfficeItem';
import { useLocation } from '../contexts/LocationContext';
import { LocationService } from 'shared-lib';

const Offices: React.FC = () => {
  const { user } = useAuth();
  const { currentLocation } = useLocation();
  const [adminOffices, setAdminOffices] = useState<Office[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const officeService = OfficeService.getInstance();
  const locationService = LocationService.getInstance();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminOffices = async () => {
      if (!user || !currentLocation) return;

      setLoading(true);
      setError(null);

      try {
        const isAdmin = await locationService.isLocationAdmin(user.uid, currentLocation.id);
        
        if (!isAdmin) {
          setError('У вас нет прав администратора для этой локации');
          setLoading(false);
          return;
        }
        
        const offices = await locationService.fetchOfficesForLocation(currentLocation.id, false);
        
        if (!offices || offices.length === 0) {
          setError('В этой локации нет доступных офисов');
          setLoading(false);
          return;
        }
        
        setAdminOffices(offices);
      } catch (err) {
        console.error("Error fetching offices for location:", err);
        setError('Не удалось загрузить офисы для выбранной локации');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminOffices();
  }, [user, locationService, currentLocation]);

  const handleEditOffice = (office: Office) => {
    navigate(`/offices/${office.id}/edit`);
  };

  const handleToggleVisibility = async (office: Office) => {
    const updatedOffice = { ...office, is_active: !office.is_active };
    try {
      await officeService.updateOffice(updatedOffice);
      setAdminOffices(adminOffices.map(o => 
        o.id === office.id ? updatedOffice : o
      ));
    } catch (err) {
      console.error("Error toggling office visibility:", err);
      setError('Failed to update office visibility');
    }
  };

  const handleEditSchedule = (officeId: string) => {
    navigate(`/offices/${officeId}/schedule`);
  };

  const formatDuration = (duration: number) => {
    return `${duration} мин`;
  };

  const content = () => {
    if (loading) {
      return <Typography>Loading...</Typography>;
    }

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    return (
      <List>
        {adminOffices.map((office) => (
          <OfficeItem
            key={office.id}
            office={office}
            isMobile={isMobile}
            isMedium={isMedium}
            handleEditOffice={handleEditOffice}
            handleToggleVisibility={handleToggleVisibility}
            handleEditSchedule={handleEditSchedule}
            formatDuration={formatDuration}
          />
        ))}
      </List>
    );
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box>
        {content()}
      </Box>
    </Container>
  );
};

export default Offices;