import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OfficeService, Booking } from 'shared-lib';
import { useEffect, useRef, useState } from 'react';

const officeService = OfficeService.getInstance();

// Новый хук для загрузки бронирований по locationId
export const useLocationBookings = (
  locationId: string, 
  startDate: Date, 
  endDate: Date
) => {
  const queryClient = useQueryClient();
  const currentDataRef = useRef<Booking[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Настраиваем подписку на Firebase по locationId
  useEffect(() => {
    if (!locationId) return () => {};
    
    // Проверяем наличие данных в кеше
    const locationQueryKey = ['locationBookings', locationId, startDate.toISOString(), endDate.toISOString()];
    const hasCachedData = queryClient.getQueryData(locationQueryKey) !== undefined;

    // Начинаем загрузку только если нет кешированных данных
    if (!hasCachedData) {
      currentDataRef.current = [];
      setIsLoading(true);
    }

    // Подписываемся на бронирования по locationId
    const unsubscribe = officeService.subscribeToLocationBookings(
      locationId,
      startDate,
      endDate,
      (bookings) => {
        // Обновляем данные
        queryClient.setQueryData<Booking[]>(
          locationQueryKey,
          bookings
        );

        currentDataRef.current = bookings;
        setIsLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [locationId, startDate, endDate, queryClient]);

  const query = useQuery<Booking[]>({
    queryKey: ['locationBookings', locationId, startDate.toISOString(), endDate.toISOString()],
    queryFn: async () => {
      if (!locationId) return [];

      return new Promise<Booking[]>((resolve) => {
        const unsubscribe = officeService.subscribeToLocationBookings(
          locationId,
          startDate,
          endDate,
          (bookings) => {
            resolve(bookings);
            unsubscribe();
          }
        );
      });
    },
    initialData: currentDataRef.current,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    enabled: !!locationId
  });

  return {
    ...query,
    isLoading
  };
};