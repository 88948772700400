import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Container, Box, FormControl, InputLabel, Select, MenuItem, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { Calendar as BigCalendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { ru } from 'date-fns/locale';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useAuth } from 'shared-lib';
import { Office, Booking } from 'shared-lib';
import BookingDetailsDialog from './BookingDetailsDialog';
import { useLocationBookings } from '../hooks/useAdminBookingQueries';
import { startOfMonth, endOfMonth, startOfWeek as dateFnsStartOfWeek, endOfWeek } from 'date-fns';
import { useLocation } from '../contexts/LocationContext';
import { LocationService } from 'shared-lib';

const locales = {
  'ru': ru,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  resource?: {
    booking: Booking;
    office: Office;
  };
}

const LoadingOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      zIndex: 1000,
    }}
  >
    <CircularProgress />
  </Box>
);

const AdminCalendar: React.FC = () => {
  console.log('🎨 Calendar rendering');

  const { user } = useAuth();
  const [adminOffices, setAdminOffices] = useState<Office[]>([]);
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [selectedOffice, setSelectedOffice] = useState<Office | null>(null);
  const { currentLocation } = useLocation();
  const locationService = LocationService.getInstance();

  // Функция для расширения диапазона дат до полных недель/месяцев
  const getExpandedDateRange = (range: Date[] | { start: Date; end: Date }) => {
    if (Array.isArray(range)) {
      // Для недельного представления
      return {
        start: dateFnsStartOfWeek(range[0], { locale: ru }),
        end: endOfWeek(range[range.length - 1], { locale: ru })
      };
    } else {
      // Для месячного представления
      return {
        start: startOfMonth(range.start),
        end: endOfMonth(range.end)
      };
    }
  };

  // Обработчик изменения диапазона дат
  const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    console.log('📅 Range change:', range);
    const expandedRange = getExpandedDateRange(range);
    console.log('📅 Expanded range:', expandedRange);
    setViewRange(expandedRange);
  };

  // Получаем расширенный диапазон дат для начального состояния
  const initialRange = useMemo(() => {
    const today = new Date();
    return getExpandedDateRange({
      start: startOfMonth(today),
      end: endOfMonth(today)
    });
  }, []);

  // Используем расширенный диапазон в useState
  const [viewRange, setViewRange] = useState(initialRange);

  // Здесь меняем логику: получаем бронирования по locationId вместо officeIds
  const { data: allLocationBookings = [], isLoading: isBookingsLoading } = useLocationBookings(
    currentLocation?.id || '',
    viewRange.start,
    viewRange.end
  );

  // Фильтруем бронирования локально в зависимости от выбранного офиса
  const bookings = useMemo(() => {
    if (!allLocationBookings.length) return [];
    
    // Если выбран конкретный офис, фильтруем бронирования
    if (selectedOfficeId !== 'all') {
      return allLocationBookings.filter(booking => booking.officeId === selectedOfficeId);
    }
    
    // Иначе возвращаем все бронирования
    return allLocationBookings;
  }, [allLocationBookings, selectedOfficeId]);

  console.log('📚 Current bookings:', bookings?.length);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchAdminOffices = async () => {
      if (!user || !currentLocation) return;

      setLoading(true);
      setError(null);

      try {
        // Проверяем, имеет ли пользователь права администратора в локации
        const isAdmin = await locationService.isLocationAdmin(user.uid, currentLocation.id);
        
        if (!isAdmin) {
          setError('У вас нет прав администратора для этой локации');
          setLoading(false);
          return;
        }
        
        // Получаем все офисы в выбранной локации
        const offices = await locationService.fetchOfficesForLocation(currentLocation.id, true);
        
        if (!offices || offices.length === 0) {
          setError('В этой локации нет доступных офисов');
          setLoading(false);
          return;
        }
        
        setAdminOffices(offices);
        
        if (offices.length === 1) {
          setSelectedOfficeId(offices[0].id);
        }
      } catch (err) {
        console.error("Error fetching offices for location:", err);
        setError('Не удалось загрузить офисы для выбранной локации');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminOffices();
  }, [user, locationService, currentLocation]);

  // Мемоизируем преобразование бронирований в события
  const events = useMemo<CalendarEvent[]>(() => 
    (bookings || []).map((booking: Booking) => {
      const office = adminOffices.find(o => o.id === booking.officeId);
      return {
        id: booking.id,
        title: `${booking.customer_first_name} ${booking.customer_last_name} • ${booking.customer_phone}`,
        start: new Date(booking.startTime),
        end: new Date(booking.endTime),
        resource: {
          booking,
          office: office!
        }
      };
    }),
    [bookings, adminOffices]
  );

  console.log('🎯 Events prepared:', events.length);

  const handleSelectEvent = (event: CalendarEvent) => {
    if (event.resource) {
      setSelectedBooking(event.resource.booking);
      setSelectedOffice(event.resource.office);
    }
  };

  const handleCloseDialog = () => {
    setSelectedBooking(null);
    setSelectedOffice(null);
  };

  // Разделяем состояния загрузки
  const isInitialLoading = loading; // загрузка офисов
  const isDataLoading = isBookingsLoading; // загрузка бронирований

  if (isInitialLoading) {
    return (
      <Container sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Выберите помещение</InputLabel>
          <Select
            value={selectedOfficeId}
            label="Выберите помещение"
            onChange={(e) => setSelectedOfficeId(e.target.value)}
          >
            {adminOffices.length > 1 && (
              <MenuItem value="all">Все помещения</MenuItem>
            )}
            {adminOffices.map((office) => (
              <MenuItem key={office.id} value={office.id}>
                {office.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ 
        position: 'relative',
        height: isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 250px)',
        '.rbc-calendar': {
          minHeight: '400px'
        },
        '.rbc-toolbar': {
          flexWrap: 'wrap',
          justifyContent: 'center',
          '.rbc-toolbar-label': {
            width: isMobile ? '100%' : 'auto',
            textAlign: 'center',
            margin: isMobile ? '8px 0' : 'inherit'
          },
          '.rbc-btn-group': {
            margin: isMobile ? '4px' : 'inherit'
          }
        },
        '.rbc-event': {
          padding: '2px 4px',
          '.rbc-event-content': {
            fontSize: isMobile ? '12px' : '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        },
        '.rbc-agenda-view': {
          table: {
            '.rbc-agenda-date-cell, .rbc-agenda-time-cell': {
              fontSize: isMobile ? '12px' : '14px',
              padding: isMobile ? '4px' : '8px'
            },
            '.rbc-agenda-event-cell': {
              fontSize: isMobile ? '12px' : '14px',
              padding: isMobile ? '4px' : '8px'
            }
          }
        }
      }}>
        {isDataLoading && <LoadingOverlay />}
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={isMobile ? 
            [Views.DAY, Views.AGENDA] : 
            [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]
          }
          defaultView={isMobile ? Views.DAY : Views.MONTH}
          onSelectEvent={handleSelectEvent}
          onRangeChange={handleRangeChange}
          culture="ru"
          messages={{
            today: 'Сегодня',
            previous: 'Назад',
            next: 'Вперед',
            month: 'Месяц',
            week: 'Неделя',
            day: 'День',
            agenda: 'Список',
            date: 'Дата',
            time: 'Время',
            event: 'Событие',
            noEventsInRange: 'Нет бронирований в выбранном периоде',
            showMore: (total) => `+${total} ещё`
          }}
          eventPropGetter={(event) => {
            const office = event.resource?.office;
            return {
              style: {
                backgroundColor: '#1976d2',
                cursor: 'pointer',
                fontSize: isMobile ? '12px' : '14px'
              },
              title: `${office?.name}\n` +
                     `Клиент: ${event.resource?.booking.customer_first_name} ${event.resource?.booking.customer_last_name}\n` +
                     `Телефон: ${event.resource?.booking.customer_phone}\n` +
                     `Email: ${event.resource?.booking.customer_email || 'не указан'}\n` +
                     `Комментарий: ${event.resource?.booking.booking_comment || 'нет'}`
            };
          }}
          components={{
            event: (props) => (
              <Box sx={{ 
                fontSize: isMobile ? '12px' : '14px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
                {props.title}
              </Box>
            )
          }}
        />
      </Box>

      {selectedBooking && selectedOffice && (
        <BookingDetailsDialog
          open={true}
          onClose={handleCloseDialog}
          booking={selectedBooking}
          office={selectedOffice}
        />
      )}
    </Container>
  );
};

export default AdminCalendar;