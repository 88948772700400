import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { collection, getDocs, addDoc, updateDoc, doc, onSnapshot, setDoc, serverTimestamp, deleteField } from 'firebase/firestore';
import { db } from 'shared-lib';
import { getAuth } from 'firebase/auth';
import { useEffect } from 'react';

export interface Location {
  id: string;
  name: string;
  address: string;
  timezone: string;
  ownerUid: string;
  // adminUids больше не используется, но пока оставляем для обратной совместимости
  adminUids?: string[];
  description?: string;
  images?: string[];
  mainImage?: string | null;
  isActive?: boolean;
  pendingRequestsCount?: number;
}

export const useLocations = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const locationsRef = collection(db, 'locations');
    const unsubscribe = onSnapshot(locationsRef, (snapshot) => {
      const locations = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Location[];
      
      queryClient.setQueryData(['locations'], locations);
    }, (error) => {
      console.error('Error listening to locations:', error);
    });

    return () => unsubscribe();
  }, [queryClient]);

  return useQuery<Location[]>({
    queryKey: ['locations'],
    queryFn: async () => {
      const querySnapshot = await getDocs(collection(db, 'locations'));
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Location[];
    }
  });
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newLocation: Omit<LocationFormData, 'adminUids'>) => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error('User not authenticated');

      // Создаем локацию с владельцем
      const locationData = {
        ...newLocation,
        ownerUid: currentUser.uid,
        isActive: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      // Больше не добавляем adminUids
      const docRef = await addDoc(collection(db, 'locations'), locationData);
      
      // Создаем запись в таблице locationUsers для владельца как админа
      const userDocId = `${currentUser.uid}_${docRef.id}`;
      await setDoc(doc(db, 'locationUsers', userDocId), {
        userId: currentUser.uid,
        locationId: docRef.id,
        role: 'admin',
        displayName: currentUser.displayName || '',
        userEmail: currentUser.email || '',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      return { id: docRef.id, ...locationData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });
    },
  });
};

interface LocationFormData {
  name: string;
  address: string;
  timezone: string;
  description?: string;
  images?: string[];
  mainImage?: string | null;
}

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (location: Location) => {
      const { id, ...locationData } = location;
      
      // Добавляем updatedAt и убираем adminUids если они есть
      const { adminUids, mainImage, ...cleanLocationData } = locationData;
      const updateData: any = {
        ...cleanLocationData,
        updatedAt: serverTimestamp()
      };
      
      // Правильно обрабатываем mainImage - удаляем поле если значение undefined или null
      if (mainImage === undefined || mainImage === null) {
        updateData.mainImage = deleteField();
      } else if (mainImage) {
        updateData.mainImage = mainImage;
      }

      await updateDoc(doc(db, 'locations', id), updateData);
      return { id, ...updateData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });
    },
  });
};

export const useDeleteLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (locationId: string) => {
      // Не удаляем физически, а просто помечаем как неактивную
      await updateDoc(doc(db, 'locations', locationId), {
        isActive: false,
        updatedAt: serverTimestamp()
      });
      return locationId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });
    },
  });
}; 