var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { collection, doc, getDoc, getDocs, getFirestore, query, where, orderBy } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
export class LocationService {
    constructor() {
        this.db = getFirestore();
        this.functions = getFunctions(undefined, 'europe-west3');
    }
    static getInstance() {
        if (!LocationService.instance) {
            LocationService.instance = new LocationService();
        }
        return LocationService.instance;
    }
    // Получение публичных локаций
    fetchPublicLocations() {
        return __awaiter(this, void 0, void 0, function* () {
            const locationsQuery = query(collection(this.db, 'locations'), where('status', '==', 'public'), where('isActive', '==', true));
            const snapshot = yield getDocs(locationsQuery);
            return snapshot.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        });
    }
    // Получение локации по ID
    getLocationById(locationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const locationRef = doc(this.db, 'locations', locationId);
            const locationDoc = yield getDoc(locationRef);
            if (locationDoc.exists()) {
                return Object.assign({ id: locationDoc.id }, locationDoc.data());
            }
            else {
                return null;
            }
        });
    }
    // Получение офисов для локации
    fetchOfficesForLocation(locationId, onlyActive = true) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('Fetching offices for location:', locationId);
            let officesQuery = query(collection(this.db, 'offices'), where('locationId', '==', locationId));
            if (onlyActive) {
                officesQuery = query(officesQuery, where('is_active', '==', true));
            }
            const snapshot = yield getDocs(officesQuery);
            console.log('Found offices:', snapshot.size);
            const offices = snapshot.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
            console.log('Mapped offices:', offices);
            return offices;
        });
    }
    // Создание запроса на резидентство
    createResidencyRequest(locationId, userId, reason, userInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const createResidencyRequestFn = httpsCallable(this.functions, 'createResidencyRequest');
            const result = yield createResidencyRequestFn({
                locationId,
                userId,
                reason,
                userInfo
            });
            return result.data;
        });
    }
    // Получение запроса на резидентство пользователя для локации
    getUserResidencyRequest(locationId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const requestsQuery = query(collection(this.db, 'locations', locationId, 'residencyRequests'), where('userId', '==', userId), orderBy('createdAt', 'desc'));
            const snapshot = yield getDocs(requestsQuery);
            if (snapshot.empty) {
                return null;
            }
            // Берем самый последний запрос
            const doc = snapshot.docs[0];
            return Object.assign({ id: doc.id }, doc.data());
        });
    }
    // Получение всех запросов пользователя на резидентство
    getUserResidencyRequests(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            // Сначала получаем все локации
            const locationsSnapshot = yield getDocs(collection(this.db, 'locations'));
            const locationsMap = new Map();
            locationsSnapshot.docs.forEach(doc => {
                locationsMap.set(doc.id, Object.assign({ id: doc.id }, doc.data()));
            });
            // Теперь для каждой локации проверяем наличие запроса от пользователя
            const results = [];
            for (const [locationId, location] of locationsMap.entries()) {
                const request = yield this.getUserResidencyRequest(locationId, userId);
                if (request) {
                    results.push({ location, request });
                }
            }
            return results;
        });
    }
    // Проверка статуса пользователя в локации
    getUserStatusInLocation(locationId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userStatusQuery = query(collection(this.db, 'locationUsers'), where('locationId', '==', locationId), where('userId', '==', userId));
            const snapshot = yield getDocs(userStatusQuery);
            if (snapshot.empty) {
                return null;
            }
            return Object.assign({}, snapshot.docs[0].data());
        });
    }
    // Получение локаций, где пользователь имеет определенную роль
    getLocationsByUserRole(userId, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const userStatusQuery = query(collection(this.db, 'locationUsers'), where('userId', '==', userId), where('role', '==', role));
            const snapshot = yield getDocs(userStatusQuery);
            if (snapshot.empty) {
                return [];
            }
            const locationIds = snapshot.docs.map(doc => doc.data().locationId);
            const locations = [];
            for (const locationId of locationIds) {
                const location = yield this.getLocationById(locationId);
                if (location) {
                    locations.push(location);
                }
            }
            return locations;
        });
    }
    // Проверяет, является ли пользователь администратором локации
    isLocationAdmin(userId, locationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userDocId = `${userId}_${locationId}`;
            const userDoc = yield getDoc(doc(this.db, 'locationUsers', userDocId));
            if (!userDoc.exists) {
                return false;
            }
            const userData = userDoc.data();
            if (!userData) {
                return false;
            }
            return userData.role === 'admin';
        });
    }
}
