import React from 'react';
import { Paper, BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import { CalendarToday, People, Settings, AccountBalance } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLocation as useLocationContext } from '../contexts/LocationContext';
import { useLocations } from '../hooks/useLocationsQueries';

const BottomNavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentLocation } = useLocationContext();
  const { data: locations } = useLocations();
  
  // Находим текущую локацию в списке локаций
  const currentLocationData = locations?.find(loc => loc.id === currentLocation?.id);
  const pendingRequestsCount = currentLocationData?.pendingRequestsCount || 0;

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={location.pathname}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
      >
        <BottomNavigationAction label="Календарь" icon={<CalendarToday />} value="/" />
        <BottomNavigationAction label="Финансы" icon={<AccountBalance />} value="/finance" />
        <BottomNavigationAction 
          label="Резиденты" 
          icon={
            <Badge badgeContent={pendingRequestsCount} color="error">
              <People />
            </Badge>
          } 
          value="/residents" 
        />
        <BottomNavigationAction label="Настройки" icon={<Settings />} value="/settings" />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNavBar;