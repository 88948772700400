import { createBrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import Calendar from './components/Calendar';
import Residents from './components/Residents';
import Settings from './components/Settings';
import Profile from './components/Profile';
import Offices from './components/Offices';
import OfficeScheduleEdit from './components/OfficeScheduleEdit';
import OfficeEditForm from './components/OfficeEditForm';
import Login from './components/Login';
import FinanceDashboard from './components/Finance/FinanceDashboard';
import { GoogleCalendarCallback, GoogleCalendarError } from 'shared-lib';
import LocationForm from './components/locations/LocationForm';
import { useLocation } from './contexts/LocationContext';
import { CircularProgress } from '@mui/material';
import LocationDrawer from './components/locations/LocationDrawer';
import { useState, useEffect } from 'react';
import LocationsPage from './components/locations/LocationsPage';
import UsersTable from './components/users/UsersTable';

const RequireLocation: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentLocation, isLoading } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoading && !currentLocation && !drawerOpen) {
        setDrawerOpen(true);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [isLoading, currentLocation, drawerOpen]);

  if (isLoading) return <CircularProgress />;
  
  return (
    <>
      {children}
      <LocationDrawer 
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      />
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      { 
        index: true, 
        element: <RequireLocation><Calendar /></RequireLocation> 
      },
      { 
        path: 'finance', 
        element: <RequireLocation><FinanceDashboard /></RequireLocation> 
      },
      { 
        path: 'residents', 
        element: <RequireLocation><Residents /></RequireLocation> 
      },
      { 
        path: 'settings', 
        element: <RequireLocation><Settings /></RequireLocation> 
      },
      { 
        path: 'offices', 
        element: <RequireLocation><Offices /></RequireLocation> 
      },
      { path: 'locations', element: <LocationsPage /> },
      { path: 'locations/:locationId/edit', element: <LocationForm /> },
      { path: 'locations/new', element: <LocationForm /> },
      { path: 'profile', element: <Profile /> },
      { path: 'offices/:officeId/edit', element: <OfficeEditForm /> },
      { path: 'offices/:officeId/schedule', element: <OfficeScheduleEdit /> },
      { path: 'offices/new', element: <OfficeEditForm /> },
      { path: 'users', element: <UsersTable /> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/oauth2callback',
    element: <GoogleCalendarCallback />,
  },
  {
    path: '/google-calendar-error',
    element: <GoogleCalendarError />,
  },
]);
