var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { doc, getDoc, setDoc, updateDoc, collection, getDocs, deleteDoc, addDoc, onSnapshot } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
export class UserService {
    constructor() {
        this.db = getFirestore();
    }
    static getInstance() {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }
    getUserData(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userDocRef = doc(this.db, 'users', userId);
            const userDocSnap = yield getDoc(userDocRef);
            if (userDocSnap.exists()) {
                return userDocSnap.data();
            }
            else {
                throw new Error('User not found');
            }
        });
    }
    updateUserSchedule(userId, schedule) {
        return __awaiter(this, void 0, void 0, function* () {
            yield updateDoc(doc(this.db, 'users', userId), {
                schedule
            });
        });
    }
    deleteUserAvailability(uid, serviceId, date) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', uid);
            const servicesRef = collection(userRef, 'services');
            const serviceDoc = doc(servicesRef, serviceId);
            const dateOverrideRef = collection(serviceDoc, 'dateOverride');
            const dateDoc = doc(dateOverrideRef, date);
            try {
                yield deleteDoc(dateDoc);
            }
            catch (error) {
                console.error('Error deleting user availability:', error);
                throw new Error('Failed to delete user availability');
            }
        });
    }
    getServiceById(userId, serviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`Fetching service. userId: ${userId}, serviceId: ${serviceId}`);
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const serviceDocRef = doc(servicesRef, serviceId);
            const serviceDocSnap = yield getDoc(serviceDocRef);
            console.log('Service document snapshot:', serviceDocSnap);
            if (serviceDocSnap.exists()) {
                const serviceData = Object.assign({ id: serviceDocSnap.id }, serviceDocSnap.data());
                console.log('Service data:', serviceData);
                return serviceData;
            }
            else {
                console.error('Service not found');
                throw new Error('Service not found');
            }
        });
    }
    saveService(userId, service) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const serviceData = Object.assign(Object.assign({}, service), { userId });
            if (service.id) {
                const serviceDocRef = doc(servicesRef, service.id);
                yield updateDoc(serviceDocRef, serviceData);
                return Object.assign(Object.assign({}, serviceData), { id: service.id });
            }
            else {
                const newServiceRef = yield addDoc(servicesRef, serviceData);
                return Object.assign(Object.assign({}, serviceData), { id: newServiceRef.id });
            }
        });
    }
    getServiceDateOverrides(userId, serviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const serviceDocRef = doc(servicesRef, serviceId);
            const dateOverridesRef = collection(serviceDocRef, 'dateOverrides');
            const querySnapshot = yield getDocs(dateOverridesRef);
            return querySnapshot.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        });
    }
    saveServiceDateOverrides(userId, serviceId, dateOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const serviceDocRef = doc(servicesRef, serviceId);
            const dateOverridesRef = collection(serviceDocRef, 'dateOverrides');
            for (const override of dateOverrides) {
                const overrideDocRef = doc(dateOverridesRef, override.id);
                yield setDoc(overrideDocRef, override);
            }
        });
    }
    deleteServiceDateOverride(userId, serviceId, dateId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const serviceDocRef = doc(servicesRef, serviceId);
            const dateOverridesRef = collection(serviceDocRef, 'dateOverrides');
            const overrideDocRef = doc(dateOverridesRef, dateId);
            yield deleteDoc(overrideDocRef);
        });
    }
    getUserServices(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const querySnapshot = yield getDocs(servicesRef);
            return querySnapshot.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        });
    }
    deleteService(userId, serviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            const serviceRef = doc(this.db, 'users', userId, 'services', serviceId);
            yield deleteDoc(serviceRef);
        });
    }
    getUserSchedule(userId, serviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const serviceDocRef = doc(servicesRef, serviceId);
            const serviceDocSnap = yield getDoc(serviceDocRef);
            if (serviceDocSnap.exists()) {
                const data = serviceDocSnap.data();
                return data.schedule || {};
            }
            return {};
        });
    }
    saveUserSchedule(userId, serviceId, schedule) {
        return __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(this.db, 'users', userId);
            const servicesRef = collection(userRef, 'services');
            const serviceDocRef = doc(servicesRef, serviceId);
            yield updateDoc(serviceDocRef, { schedule });
        });
    }
    isUserVerified(uid) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const userDoc = yield getDoc(doc(this.db, 'users', uid));
            if (!userDoc.exists()) {
                return false;
            }
            return ((_a = userDoc.data()) === null || _a === void 0 ? void 0 : _a.isVerified) === true;
        });
    }
    onUserDataChange(uid, callback) {
        return onSnapshot(doc(this.db, 'users', uid), (docSnapshot) => {
            callback(docSnapshot.data());
        });
    }
}
export default UserService;
