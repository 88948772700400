import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Paper, Box, Button, CircularProgress, Snackbar, Fab, Container,
  Accordion, AccordionSummary, AccordionDetails, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OfficeService, Schedule, ScheduleEdit, DateOverridesEdit, DateOverride } from 'shared-lib';
import { useUnsavedChangesWarning } from '../hooks/useUnsavedChangesWarning';
import { ru } from 'date-fns/locale';
import { format, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const OfficeScheduleEdit: React.FC = () => {
  const { officeId } = useParams<{ officeId: string }>();
  const [schedule, setSchedule] = useState<Schedule>({});
  const [dateOverrides, setDateOverrides] = useState<DateOverride[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [deletedDays, setDeletedDays] = useState<string[]>([]);

  const officeService = OfficeService.getInstance();

  useEffect(() => {
    const fetchOfficeSchedule = async () => {
      if (!officeId) return;
      
      try {
        setLoading(true);
        const office = await officeService.getOfficeById(officeId);
        if (office) {
          const officeSchedule = await officeService.getOfficeSchedule(officeId);
          setSchedule(officeSchedule);
          const fetchedDateOverrides = await officeService.getOfficeDateOverrides(officeId);
          
          // Фильтруем прошедшие даты
          const today = startOfDay(new Date());
          const filteredOverrides = fetchedDateOverrides.filter(override => {
            const overrideDate = new Date(override.id);
            return overrideDate >= today;
          });
          
          setDateOverrides(filteredOverrides);
        }
      } catch (error) {
        console.error('Error fetching office schedule:', error);
        setError('Failed to load office schedule');
      } finally {
        setLoading(false);
      }
    };

    fetchOfficeSchedule();
  }, [officeId, officeService]);

  useUnsavedChangesWarning(
    isChanged,
    'У вас есть несохраненные изменения в расписании. Вы уверены, что хотите покинуть эту страницу?'
  );

  const handleSave = async () => {
    if (!officeId) return;
    
    try {
      setSaving(true);
      await officeService.updateOfficeSchedule(officeId, schedule);
      await officeService.saveOfficeDateOverrides(officeId, dateOverrides);
      
      // Delete removed date overrides
      for (const date of deletedDays) {
        await officeService.deleteOfficeDateOverride(officeId, date);
      }

      setError('Расписание успешно сохранено');
      setIsChanged(false);
      setDeletedDays([]);
    } catch (error) {
      console.error('Error saving office schedule:', error);
      setError('Failed to save schedule');
    } finally {
      setSaving(false);
    }
  };

  const handleAddDate = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDate(null);
  };

  const handleConfirmDate = () => {
    if (selectedDate) {
      const dateInOfficeTimezone = toZonedTime(selectedDate, 'Europe/Samara');
      const dateString = format(dateInOfficeTimezone, 'yyyy-MM-dd');

      if (!dateOverrides.find(override => override.id === dateString)) {
        const newOverride: DateOverride = { 
          id: dateString, 
          isActive: false,
          intervals: []
        };
        setDateOverrides(prev => [...prev, newOverride]);
        setIsChanged(true);
      }
    }
    handleCloseDialog();
  };

  const handleIntervalChange = (overrideIndex: number, intervalIndex: number, field: 'start' | 'end', value: string) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: newOverrides[overrideIndex].intervals.map((interval, idx) => 
          idx === intervalIndex ? { ...interval, [field]: value } : interval
        )
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const addInterval = (overrideIndex: number) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: [...newOverrides[overrideIndex].intervals, { start: '09:00', end: '18:00' }]
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const removeInterval = (overrideIndex: number, intervalIndex: number) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: newOverrides[overrideIndex].intervals.filter((_, idx) => idx !== intervalIndex)
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const removeDay = (overrideIndex: number) => {
    const dayToRemove = dateOverrides[overrideIndex];
    setDateOverrides(prev => prev.filter((_, idx) => idx !== overrideIndex));
    setDeletedDays(prev => [...prev, dayToRemove.id]);
    setIsChanged(true);
  };

  const handleDayTypeChange = (overrideIndex: number, isActive: boolean) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        isActive
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <Container sx={{ mt: 4 }}>
        <Box sx={{ pb: 10 }}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>График работы</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Укажите здесь стандартный еженедельный график работы офиса. 
                Этот график будет применяться ко всем дням, если не указано иное 
                в разделе "Особый график для отдельных дат".
              </Typography>
              <ScheduleEdit 
                schedule={schedule} 
                onChange={(newSchedule) => {
                  setSchedule(newSchedule);
                  setIsChanged(true);
                }} 
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Особый график на конкретные даты</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Здесь вы можете задать особый график работы для конкретных дат, когда он отличается от обычного.
                Например:
                <ul>
                  <li>Сокращенный график в праздничные дни</li>
                  <li>Рабочие дни в выходные</li>
                  <li>Любые другие изменения обычного графика</li>
                </ul>
              </Typography>
              <DateOverridesEdit
                dateOverrides={dateOverrides}
                onAddDate={handleAddDate}
                onRemoveDay={removeDay}
                onIntervalChange={handleIntervalChange}
                onAddInterval={addInterval}
                onRemoveInterval={removeInterval}
                onDayTypeChange={handleDayTypeChange}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        
        <Fab 
          color="primary" 
          size="small" 
          onClick={scrollToTop}
          sx={{ position: 'fixed', bottom: 80, right: 16 }}
        >
          <KeyboardArrowUpIcon />
        </Fab>

        <Paper 
          elevation={3} 
          sx={{ 
            position: 'fixed', 
            bottom: 0, 
            left: 0, 
            right: 0, 
            p: 2, 
            display: 'flex', 
            justifyContent: 'flex-end',
            backgroundColor: 'background.paper',
            zIndex: 1100,
          }}
        >
          <Button 
            variant="contained" 
            onClick={handleSave} 
            disabled={saving || !isChanged}
            startIcon={<SaveIcon />}
          >
            {saving ? 'Сохранение...' : 'Сохранить расписание'}
          </Button>
        </Paper>
        
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Выберите дату</DialogTitle>
          <DialogContent>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              timezone="Europe/Samara"
              minDate={startOfDay(new Date())}
              shouldDisableDate={(date) => {
                const dateString = format(toZonedTime(date, 'Europe/Samara'), 'yyyy-MM-dd');
                return dateOverrides.some(override => override.id === dateString);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Отмена</Button>
            <Button onClick={handleConfirmDate} disabled={!selectedDate}>
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </LocalizationProvider>
  );
};

export default OfficeScheduleEdit;
