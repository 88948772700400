import React, { useState } from 'react';
import { Box, Grid, Paper, Typography, useTheme, useMediaQuery, Select, MenuItem, FormControl, InputLabel, CircularProgress, Alert, Chip, Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EventIcon from '@mui/icons-material/Event';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useLocation } from '../../contexts/LocationContext';
import { Period, useFinanceMetrics, getPeriodLabel, useOfficeOptions, useClientOptions, FinanceFilters } from '../../hooks/useFinanceQueries';
import { format, isValid } from 'date-fns';
import { ru } from 'date-fns/locale';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

const MetricValue = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginTop: theme.spacing(1),
}));

const MetricLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
}));

const BookingItemPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  position: 'relative',
}));

// Преобразование копеек в рубли
const kopecksToRubles = (kopecks: number | undefined): string => {
  if (kopecks === undefined || kopecks === null) return '0';
  return (kopecks / 100).toLocaleString('ru-RU');
};

// Объединенный статус бронирования и оплаты
const getCombinedStatus = (booking: any): { label: string; color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" } => {
  const isPaid = booking.payment && 
    (booking.payment.status === 'CONFIRMED' || booking.payment.status === 'PAID');
  const isCancelled = booking.status === 'CANCELLED';
  
  if (isPaid) {
    if (isCancelled) {
      // Оплачено, но отменено - значит был возврат
      return { 
        label: 'Отмена с возвратом', 
        color: 'error'
      };
    } else {
      // Оплачено и не отменено
      return { 
        label: 'Оплачено', 
        color: 'success'
      };
    }
  } else {
    if (isCancelled) {
      // Не оплачено и отменено
      return { 
        label: 'Отменено', 
        color: 'error'
      };
    } else if (booking.status === 'CONFIRMED') {
      // Подтверждено, но не оплачено
      return { 
        label: 'Ожидает оплату', 
        color: 'primary'
      };
    } else if (booking.status === 'PENDING' || (booking.payment && booking.payment.status === 'PENDING')) {
      // В статусе PENDING также ожидает оплату
      return { 
        label: 'Ожидает оплату', 
        color: 'warning'
      };
    } else {
      // Другие статусы (NEW, etc.)
      return { 
        label: 'Создано', 
        color: 'default'
      };
    }
  }
};

// Форматирование имени клиента
const formatClientName = (firstName?: string, lastName?: string): string => {
  if (!firstName && !lastName) return 'Нет данных';
  
  const formattedFirstName = firstName || '';
  const formattedLastName = lastName ? `${lastName.charAt(0)}.` : '';
  
  return `${formattedFirstName} ${formattedLastName}`.trim();
};

// Форматирование временного интервала
const formatTimeInterval = (startTime: Date, endTime: Date): string => {
  if (!startTime || !endTime || !isValid(startTime) || !isValid(endTime)) {
    return 'Интервал не указан';
  }
  
  const startDate = format(startTime, 'dd MMMM yyyy', { locale: ru });
  const startHour = format(startTime, 'HH:mm', { locale: ru });
  const endHour = format(endTime, 'HH:mm', { locale: ru });
  
  return `${startDate}, ${startHour} - ${endHour}`;
};

// Компонент карточки бронирования
const BookingItem = ({ booking }: { booking: any }) => {
  const status = getCombinedStatus(booking);
  
  return (
    <BookingItemPaper elevation={3}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography 
          variant="body1" 
          sx={{ 
            fontWeight: 'bold',
            color: 'text.primary' 
          }}
        >
          {booking.officeName || 'Офис'}
        </Typography>
        <Chip 
          label={status.label}
          color={status.color}
          size="small"
        />
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CalendarTodayIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 20 }} />
          <Typography variant="body2">
            {formatTimeInterval(booking.startTime, booking.endTime)}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography 
              component="span" 
              variant="body2" 
              sx={{ 
                fontWeight: 'bold',
                whiteSpace: 'nowrap'
              }}
            >
              {kopecksToRubles(booking.price)} ₽
            </Typography>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        width: '100%',
        alignItems: 'center',
        mt: 1
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PersonIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 20 }} />
          <Typography variant="body2">
            {formatClientName(booking.customer_first_name, booking.customer_last_name)}
          </Typography>
        </Box>
      </Box>
    </BookingItemPaper>
  );
};

export const FinanceDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentLocation } = useLocation();
  
  // Состояние фильтров
  const [filters, setFilters] = useState<FinanceFilters>({
    period: 'currentMonth',
    officeId: undefined,
    clientId: undefined
  });
  
  // Получение списка офисов для фильтра
  const officeOptions = useOfficeOptions(currentLocation?.id, filters.period);
  
  // Получение списка клиентов для фильтра
  const clientOptions = useClientOptions(currentLocation?.id, filters.period);
  
  // Получение метрик с применением фильтров
  const { 
    currentRevenue,
    upcomingRevenue,
    totalBookings,
    cancelledBookings,
    cancellationRate,
    paidBookings = [],
    isLoading,
    error
  } = useFinanceMetrics(currentLocation?.id, filters);

  // Периоды для выпадающего списка
  const periods: Period[] = ['currentMonth', 'month1', 'month2', 'month3'];
  
  // Обработчик изменения периода
  const handlePeriodChange = (period: Period) => {
    setFilters(prev => ({ ...prev, period }));
  };
  
  // Обработчик изменения офиса
  const handleOfficeChange = (officeId: string | undefined) => {
    setFilters(prev => ({ ...prev, officeId }));
  };
  
  // Обработчик изменения клиента
  const handleClientChange = (clientId: string | undefined) => {
    setFilters(prev => ({ ...prev, clientId }));
  };
  
  // Сброс всех фильтров
  const resetFilters = () => {
    setFilters({
      period: 'currentMonth',
      officeId: undefined,
      clientId: undefined
    });
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Ошибка загрузки данных: {error instanceof Error ? error.message : 'Неизвестная ошибка'}
      </Alert>
    );
  }

  return (
    <Box sx={{ padding: theme.spacing(3) }}>
      {/* Панель фильтров */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'stretch' : 'center'}
          gap={2}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Период</InputLabel>
            <Select
              value={filters.period}
              label="Период"
              onChange={(e) => handlePeriodChange(e.target.value as Period)}
              fullWidth={isMobile}
            >
              {periods.map((period) => (
                <MenuItem key={period} value={period}>
                  {getPeriodLabel(period)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <FormControl sx={{ minWidth: 200, flex: 1 }}>
            <Autocomplete
              options={officeOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Офис" />}
              value={officeOptions.find(o => o.id === filters.officeId) || null}
              onChange={(event, newValue) => handleOfficeChange(newValue?.id)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </FormControl>
          
          <FormControl sx={{ minWidth: 200, flex: 1 }}>
            <Autocomplete
              options={clientOptions}
              getOptionLabel={(option) => option.fullName}
              renderInput={(params) => <TextField {...params} label="Клиент" />}
              value={clientOptions.find(c => c.id === filters.clientId) || null}
              onChange={(event, newValue) => handleClientChange(newValue?.id)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </FormControl>
          
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              cursor: 'pointer',
              color: 'primary.main',
              '&:hover': { textDecoration: 'underline' }
            }}
            onClick={resetFilters}
          >
            <FilterAltIcon sx={{ mr: 0.5 }} fontSize="small" />
            <Typography variant="body2">Сбросить фильтры</Typography>
          </Box>
        </Box>
      </Paper>

      {/* Метрики */}
      <Grid container spacing={isMobile ? 2 : 3} mb={5}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper elevation={2}>
            <TrendingUpIcon color="primary" sx={{ fontSize: isMobile ? 32 : 40 }} />
            <MetricValue sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
              {kopecksToRubles(currentRevenue)} ₽
            </MetricValue>
            <MetricLabel>Текущая выручка</MetricLabel>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper elevation={2}>
            <AccountBalanceWalletIcon color="info" sx={{ fontSize: isMobile ? 32 : 40 }} />
            <MetricValue sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
              {kopecksToRubles(upcomingRevenue)} ₽
            </MetricValue>
            <MetricLabel>Будущая выручка</MetricLabel>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper elevation={2}>
            <EventIcon color="success" sx={{ fontSize: isMobile ? 32 : 40 }} />
            <MetricValue sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
              {totalBookings}
            </MetricValue>
            <MetricLabel>Платных бронирований</MetricLabel>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper elevation={2}>
            <CancelIcon color="error" sx={{ fontSize: isMobile ? 32 : 40 }} />
            <MetricValue sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
              {cancelledBookings} ({(cancellationRate * 100).toFixed(1)}%)
            </MetricValue>
            <MetricLabel>Отмененные</MetricLabel>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Список бронирований */}
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Детальная информация о платных бронированиях
        </Typography>
        
        <Typography variant="body2" color="text.secondary">
          {paidBookings.length} {paidBookings.length === 1 ? 'запись' : 
           paidBookings.length >= 2 && paidBookings.length <= 4 ? 'записи' : 'записей'}
        </Typography>
      </Box>

      <Box sx={{ maxWidth: isMobile ? '100%' : '600px', margin: '0 auto' }}>
        {paidBookings.map((booking) => (
          <BookingItem key={booking.id} booking={booking} />
        ))}
        
        {paidBookings.length === 0 && (
          <Typography variant="body1" textAlign="center" py={4} color="text.secondary">
            Нет бронирований за выбранный период
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FinanceDashboard; 